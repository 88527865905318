export const LoadQuizzes = "LOAD_QUIZZES";
export const LoadQuizzesSuccess = "LOAD_QUIZZES_SUCCESS";
export const LoadQuizzesFail = "LOAD_QUIZZES_FAIL";
export const SetCurrentQuizPage = "SET_CURRENT_QUIZ_PAGE";
export const SetIsSelectedAnswer = "SET_IS_SELECTED_ANSWER";
export const SetIsEndOfChapter = " SET_IS_END_OF_CHAPTER";
export const SetStartTime = "SET_START_TIME";
export const SetEndTime = "SET_END_TIME";
export const SetCorrectAnswers = "SET_CORRECT_ANSWERS";
export const ClearCorrectAnswers = "CLEAR_CORRECT_ANSWERS";
export const LoadInitialQuizScore = "LOAD_INITIAL_QUIZ_SCORE";
export const LoadInitialQuizScoreSuccess = "LOAD_INITIAL_QUIZ_SCORE_SUCCESS";
export const LoadInitialQuizScoreFail = "LOAD_INITIAL_QUIZ_SCORE_FAIL";
export const LoadFinalQuizScore = "LOAD_FINAL_QUIZ_SCORE";
export const LoadFinalQuizScoreSuccess = "LOAD_FINAL_QUIZ_SCORE_SUCCESS";
export const LoadFinalQuizScoreFail = "LOAD_FINAL_QUIZ_SCORE_FAIL";
export const LoadRegionsScore = "LOAD_REGIONS_SCORE";
export const LoadRegionsScoreSuccess = "LOAD_REGIONS_SCORE_SUCCESS";
export const LoadRegionsScoreFail = "LOAD_REGIONS_SCORE_FAIL";
export const LoadGlobalQuizScore = "LOAD_GLOBAL_QUIZ_SCORE";
export const LoadGlobalQuizScoreSuccess = "LOAD_GLOBAL_QUIZ_SCORE_SUCCESS";
export const LoadGlobalQuizScoreFail = "LOAD_GLOBAL_QUIZ_SCORE_FAIL";
export const SetCurrentChapterNumber = "SET_CURRENT_CHAPTER_NUMBER";
