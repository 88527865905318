import "./Ranking.component.scss";
import { QuizScoreModel } from "../../models/quiz-score.model";
import euFlag from "../../assets/icons/eu-flag.svg";
import React, { useContext, useEffect, useState } from "react";
import { RegionContext } from "../../reducers/region.reducer";
import { RegionModel } from "../../models";
import { QuizContext } from "../../reducers/quiz.reducer";
import { useGetFinalQuizScore } from "../../hooks/useGetFinalQuizScore";
import { useMediaQuery } from "react-responsive";
import { mobileDeviceResolution } from "../../utils/utils";
import { createStyles, makeStyles, Popover } from "@material-ui/core";
import { Title } from "../Title/Title.component";
import { useGetRegionsScore } from "../../hooks/useGetRegionsScore";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      display: "flex",
    },
    title: {
      marginBottom: "20px",
    },
    regionsScoreContainer: {
      display: "flex",
      flexDirection: "column",
    },
    regionScoreContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    flagAndNameContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    regionName: {
      color: "#fff",
      marginLeft: "10px",
      fontSize: "18px",
    },
    icon: {
      width: "30px",
    },
    line: {
      border: "dashed 1px #323651",
      marginBottom: "5px",
    },
    score: {
      color: "#fff",
      fontSize: "18px",
    },
  })
);
interface RankingProps {
  element?: HTMLButtonElement | null;
  onClose?: () => void;
}

export function Ranking(props: RankingProps) {
  const classes = useStyles();
  const regionContext = useContext(RegionContext);
  const quizContext = useContext(QuizContext);
  const [regionsScore, setRegionsScore] = useState(Array<QuizScoreModel>());
  const [globalScore, setGlobalScore] = useState(new QuizScoreModel());
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>();

  const handleClose = () => {
    setAnchorEl(null);
    if (props.onClose) {
      props.onClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (props.element) {
      setAnchorEl(props.element);
    }
    GetRegionsScore();
  }, []);

  useEffect(() => {
    setGlobalScore(quizContext.state.globalScore);
  }, [quizContext.state.globalScore]);

  useEffect(() => {
    setRegionsScore(quizContext.state.regionsScore);
  }, [quizContext.state.regionsScore]);

  function GetRegionsScore() {
    useGetRegionsScore(quizContext.dispatch);
  }

  return (
    <div className="ranking-root fade-in-container">
      {!isMobileDevice ? (
        <div className="ranking-root-container">
          <div className="title">{t("Ranking")}</div>
          <div className="global-score-container">
            <img className="eu-flag" src={euFlag} alt="euFlag"></img>
            <div className="global-score">{globalScore?.contributionPercentage} %</div>
          </div>
          <div className="regions-score-container">
            {regionsScore.map((score) => (
              <div
                className={
                  regionContext.state.selectedRegion?.id === score.regionId
                    ? "region-score-container selected-region"
                    : "region-score-container"
                }
                key={score.regionId}
              >
                <div className="flag">
                  <img className="icon" src={score.icon} alt="flag"></img>
                </div>
                <div
                  className={
                    regionContext.state.selectedRegion?.id === score.regionId
                      ? "score selected-region"
                      : "score"
                  }
                >
                  {score.contributionPercentage}%
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <Popover
            marginThreshold={0}
            classes={{ paper: "customPopupPaperMobile" }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <div className={classes.title}>
              <Title title="Ranking" dark={true} />
            </div>
            <div className={classes.regionsScoreContainer}>
              {regionsScore.map((score) => (
                <div>
                  <div
                    className={classes.regionScoreContainer}
                    key={score.regionId}
                  >
                    <div className={classes.flagAndNameContainer}>
                      <div className="flag">
                        <img
                          className={classes.icon}
                          src={score.icon}
                          alt="flag"
                        ></img>
                      </div>
                      <div className={classes.regionName}>
                        {score.regionName}
                      </div>
                    </div>
                    <div className={classes.score}>{score.contributionPercentage}%</div>
                  </div>
                  <div className={classes.line}></div>
                </div>
              ))}
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
}
