export class Config {
  public apiUrl: string = '';

  public constructor(fields: {
    apiUrl: string;
  }) {
    this.apiUrl = fields.apiUrl;
  }
}

export enum ConfigEnv {
  DEV = 'dev',
  PRODUCTION = 'prod',
}
