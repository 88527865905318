import * as React from "react";
import { RegionModel } from "../../models/region.model";
import "./RegionAndFlag.component.scss";

interface RegionAndFlagProps {
  shouldShowAbbreviation?: boolean;
  region: RegionModel;
}

export function RegionAndFlag(props: RegionAndFlagProps) {
  return (
    <div className="region-and-flag-root-container">
      <div className="region-and-flag-container">
        <img className="flag" src={props.region.icon} alt={props.region.name} />
        <div className="region">
          {props.shouldShowAbbreviation
            ? props.region.abbreviation
            : props.region.name}
        </div>
      </div>
    </div>
  );
}
