import * as React from "react";
import "./Title.component.scss";
import { Typography } from "@material-ui/core";
import { CSSTransition } from "react-transition-group";

interface TitleProps {
  title?: string;
  multipleLinesTitle?: string[];
  dark: boolean;
  smallSize?: boolean;
  largeSize?: boolean;
  centered?: boolean;
  transformed?: boolean;
  hasAnimation?: boolean;
  fontSize?: string;
}

export function Title(props: TitleProps) {
  return (
    <div
      className={`title-root-container ${
        props.centered ? "centered-text" : ""
      } ${props.transformed ? "transformed-title" : ""}`}
    >
      {props.title ? (
        <Typography
          className={
            props.dark
              ? `title-text-container dark-mode ${props.fontSize}`
              : "title-text-container light-mode"
          }
          variant={props.largeSize ? "h1" : props.smallSize ? "h2" : "h3"}
        >
          <div className={props.hasAnimation ? "animation-effect" : ""}>
            {props.title}
          </div>
        </Typography>
      ) : props.multipleLinesTitle ? (
        props.multipleLinesTitle.map((title) => {
          return (
            <div key={title}>
              {
                title ? (
                  <Typography
                  className={
                    props.dark
                      ? "title-text-container dark-mode"
                      : "title-text-container light-mode"
                  }
                  variant={props.largeSize ? "h1" : props.smallSize ? "h2" : "h3"}
                >
                  <div className={props.hasAnimation ? "animation-effect" : ""}>
                    {title}
                  </div>
                </Typography>
                ): null
              }

            </div>
          );
        })
      ) : null}
    </div>
  );
}
