export class AnswerModel {
  id!: number;
  isActive!: boolean;
  isDesired!: boolean;
  description!: string;
  answer!: string;
  position!: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
