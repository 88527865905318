export class RegionModel {
  id!: number;
  abbreviation!: string;
  name!: string;
  languages!: string[];
  icon!: string;
  domain!: string;
  i18n!: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
