import { AnswerModel } from ".";

export class QuestionReportModel {
  id!: number;
  question!: string;
  isCorrect!: boolean;
  correctAnswer!: AnswerModel[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
