import * as React from "react";
import * as RegionActionTypes from "./region.actionTypes";

const initialState = {
  selectedRegion: null,
  availableRegions: [],
  loadingRegions: false,
  animationIsNeeded: false,
  error: null,
};

const RegionContext = React.createContext<{
  state?: any;
  dispatch?: React.Dispatch<any>;
}>({});

const regionReducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case RegionActionTypes.SelectRegion:
      return {
        ...state,
        selectedRegion: action.payload,
      };
    case RegionActionTypes.LoadRegions:
      return {
        availableRegions: [],
        loadingRegions: true,
      };
    case RegionActionTypes.LoadRegionsSuccess:
      return {
        ...state,
        availableRegions: action.payload,
        loadingRegions: false,
      };
    case RegionActionTypes.LoadRegionsFail:
      return {
        loadingRegions: false,
        error: action.payload,
      };
    case RegionActionTypes.SetAnimationFlag:
      return {
        ...state,
        animationIsNeeded: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

const RegionProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(regionReducer, initialState);
  const value = { state, dispatch };
  return (
    <RegionContext.Provider value={value}>
      {props.children}
    </RegionContext.Provider>
  );
};

export { RegionContext, RegionProvider };
