import { useState, useEffect, useContext } from "react";
import { AnswerModel, QuestionModel } from "../../models";
import { Title } from "../Title/Title.component";
import "./Question.component.scss";
import check from "../../assets/icons/check.svg";
import { useMediaQuery } from "react-responsive";
import { Button } from "@material-ui/core";
import { mobileDeviceResolution } from "../../utils/utils";
import { QuizContext } from "../../reducers/quiz.reducer";
import { useTranslation } from "react-i18next";

interface QuestionProps {
  question: QuestionModel;
  onShowResultClick: any;
  showSubmitButton: boolean;
  totalQuestions: number;
  doneQuestions: number;
}

enum questionTypes {
  multipleAnswers = "checkbox",
  singleAnswer = "radio",
  trueOrFalse = "boolean",
}

export function Question(props: QuestionProps) {
  const [answers, setAnswers] = useState(new Array<AnswerModel>());
  const [selectedAnswers, setSelectedAnswers] = useState(Array<AnswerModel>());
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const quizContext = useContext(QuizContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setAnswers(props.question.answers);
  }, [props.question, answers]);

  useEffect(() => {
    if (isMobileDevice) {
      props.onShowResultClick(selectedAnswers);
    }
  }, [selectedAnswers]);

  function countWords(str: string) {
    if (str) {
      str = str.replace(/(^\s*)|(\s*$)/gi, "");
      str = str.replace(/[ ]{2,}/gi, " ");
      str = str.replace(/\n /, "\n");
      return str.split(" ").length;
    }
  }

  function getQuestionFontSize() {
    const numberOfWords = countWords(props.question.question);
    if (numberOfWords && numberOfWords < 10) {
      return isMobileDevice ? "large-size-mobile" : "large-size";
    } else if (numberOfWords && numberOfWords < 20) {
      return isMobileDevice ? "medium-size-mobile" : "medium-size";
    } else {
      return isMobileDevice ? "small-size-mobile" : "small-size";
    }
  }

  function onSelectAnswer(answer: AnswerModel) {
    const isNotSelected = selectedAnswers.findIndex((a) => a.id === answer.id);
    if (isNotSelected === -1) {
      if (props.question.type === questionTypes.multipleAnswers) {
        setSelectedAnswers([...selectedAnswers, answer]);
      } else {
        setSelectedAnswers([answer]);
      }
    } else {
      setSelectedAnswers(selectedAnswers.filter((a) => answer.id !== a.id));
    }
  }

  function getAnswerContainerStyle(answer: AnswerModel) {
    if (selectedAnswers.findIndex((a) => a.id === answer.id) !== -1) {
      return isMobileDevice
        ? "selected-answer-container answer-container-on-mobile"
        : "selected-answer-container answer-container-on-desktop";
    } else {
      return isMobileDevice
        ? "answer-container answer-container-on-mobile"
        : "answer-container answer-container-on-desktop";
    }
  }
  function onSubmitAnswerClick() {
    props.onShowResultClick(selectedAnswers);
  }

  return (
    <div className="question-root-container fade-in-container">
      <div
        className={
          isMobileDevice
            ? "center-container mobile-version"
            : "center-container"
        }
      >
        <div>
          <div className="quiz-progress">
            <div className="label">
              {t("ChapterNumber", {
                chapterNumber: quizContext.state.currentChapterNumber,
              })}
            </div>
            <div
              className={
                isMobileDevice
                  ? "done-questions mobile-version"
                  : "done-questions"
              }
            >
              {props.doneQuestions + 1}
              <div
                className={
                  isMobileDevice
                    ? "total-questions mobile-version"
                    : "total-questions"
                }
              >
                / {props.totalQuestions}
              </div>
            </div>
          </div>
          <Title
            title={props.question.question}
            dark={true}
            centered={true}
            transformed={true}
            fontSize={getQuestionFontSize()}
          />
        </div>
        <div
          className={
            isMobileDevice
              ? "answers-container mobile-version"
              : "answers-container"
          }
        >
          {answers && answers.length > 0
            ? answers.map((answer) => (
                <div
                  key={answer.id}
                  className={getAnswerContainerStyle(answer)}
                  onClick={() => onSelectAnswer(answer)}
                >
                  <div className="answer" key={answer.id}>
                    {answer.answer}
                  </div>
                  {selectedAnswers.findIndex((a) => a.id === answer.id) !==
                  -1 ? (
                    <img className="check" src={check} alt="check" />
                  ) : null}
                </div>
              ))
            : null}
        </div>
        {!isMobileDevice ? (
          <div className="buttons-container">
            <div className="submit-button-container">
              <Button
                disabled={!(selectedAnswers.length > 0)}
                className={
                  selectedAnswers.length > 0 ? "submit" : "submit disabled"
                }
                variant="contained"
                color="secondary"
                onClick={onSubmitAnswerClick}
              >
                {t("Submit")}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
