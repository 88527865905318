import { useContext, useEffect, useState } from "react";
import "./RegionAndFlagList.component.scss";
import { RegionAndFlag } from "../RegionAndFlag/RegionAndFlag.component";
import { RegionModel } from "../../models/region.model";
import { RegionContext } from "../../reducers/region.reducer";
import * as RegionActionTypes from "../../reducers/region.actionTypes";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { LanguageContext } from '../../reducers/language.reducer';
import { ContentContext } from '../../reducers/content.reducer';
import * as ContentActionTypes from "../../reducers/content.actionTypes";
import * as LanguageActionTypes from "../../reducers/language.actionTypes";

export function RegionAndFlagList() {
  const history = useHistory();
  const { state, dispatch } = useContext(RegionContext);
  const [regions, setRegions] = useState(Array<RegionModel>());
  const languageContext = useContext(LanguageContext);
  const contentContext = useContext(ContentContext);

  useEffect(() => {
    setRegions(state.availableRegions);
  }, [state]);

  function onSelectRegion(region: RegionModel) {
    dispatch?.({ type: RegionActionTypes.SelectRegion, payload: region });

    let path = "campaign";
    history.push(path);

    contentContext.dispatch?.({type: ContentActionTypes.ClearContent});
    languageContext.dispatch?.({type: LanguageActionTypes.ClearLanguages});
  }

  return (
    <div className="region-and-flag-list-root">
      <div className="region-and-flag-list-container">
        {regions && regions.length ? (
          regions.map((region) => (
            <div key={region.id} onClick={() => onSelectRegion(region)}>
              <RegionAndFlag region={region} />
            </div>
          ))
        ) : (
          <CircularProgress className="loading-indicator" />
        )}
      </div>
    </div>
  );
}
