import "./ChapterSections.component.scss";
import { Title } from "../Title/Title.component";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { InfoContentModel } from "../../models/info-content.model";
import { mobileDeviceResolution, pages } from "../../utils/utils";
import { useMediaQuery } from "react-responsive";
import React, { useContext, useEffect, useState } from "react";
import { OfficerWild } from "../OfficerWild/OfficerWild.component";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { QuizContext } from "../../reducers/quiz.reducer";
interface ChapterSectionProps {
  content: InfoContentModel;
  displayOfficerWild: boolean;
  officerWildClick: () => void;
}

export function ChapterSections(props: ChapterSectionProps) {
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const [showSummary, setShowSummary] = useState(new Array<boolean>());
  const { t, i18n } = useTranslation();
  const quizContext = useContext(QuizContext);

  useEffect(() => {
    createShowSummaryList();
  }, [props.content]);

  function onSectionChange(event: object, expanded: boolean, index: number) {
    const newShowSummary = [...showSummary];
    newShowSummary[index] = !expanded;

    // Use this timeout to not immediately hide/display summary
    setTimeout(() => {
      setShowSummary(newShowSummary);
    }, 200);
  }

  function createShowSummaryList() {
    const newShowSummary: boolean[] = [];
    props.content.sections.map((section) => {
      newShowSummary.push(true);
    });
    setShowSummary(newShowSummary);
  }

  function isSectionExpanded() {
    return showSummary.filter(item => item === false).length !== 0
  }

  function quizShortcutIsNeeded() {
    switch (quizContext.state.currentQuizPage) {
      case pages.questionPage:
      case pages.resultPage:
      case pages.correctAnswer:
      case pages.submitPage:
      case pages.chapterEndPage:
        return false
      default:
        return true;
    }
  }

  return (
    <div className="chapter-sections-page-root">
      <div className="title">
        <Title
          title={props.content.title}
          dark={true}
          transformed={isMobileDevice ? false : true}
        />
      </div>
      <div className={` ${'chapter-sections-container'} ${isMobileDevice ? 'mobile-chapter-sections-container' : ''}`}>
            <div className="section-content">
            { props.content.order === 5 ? (
                  <div className="section-container sections-container-no-margin">
                    <div
                      className="chapter-content"
                      dangerouslySetInnerHTML={{ __html: `${props.content.content}` }}>
                    </div>
                  </div>

            ): null }
            {props.content.sections.map((section, index) => (
                (
                  <div key={section.sectionNumber}>
                    <Accordion
                      onChange={(event: object, expanded: boolean) =>
                        onSectionChange(event, expanded, index)
                      }
                    >
                      <AccordionSummary expandIcon={showSummary[index] ? <ExpandMoreIcon /> : <CloseIcon />}>
                        <div className={` ${'section-header'} ${isMobileDevice && showSummary[index] ? 'fade-section-header' : ''} ${!isMobileDevice && showSummary[index] && isSectionExpanded() ? 'fade-section-header' : ''}`}>
                          <div className="number-container">
                            <div className="section-number">
                              {section.sectionNumber}
                            </div>
                          </div>
                          <div className="section-info-container">
                            <div className="section-title-container">
                              <div className="section-title">
                                {section.title}
                              </div>
                            </div>
                            {
                              showSummary[index] && !isMobileDevice ? (
                                <div className="summary-container">
                                  {section.teaser}
                                </div>
                              ) : null
                            }
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="section-container">
                          <div
                            className="chapter-content"
                            dangerouslySetInnerHTML={{ __html: `${section.content}` }}
                          ></div>
                          {quizShortcutIsNeeded() && props.content.id !== 5 ? (
                            <div className="bottom-section-container">
                              <div
                                className="officer-wild-container-take-quiz"
                                onClick={props.officerWildClick}
                              >
                                <div
                                  className={
                                    isMobileDevice
                                      ? "officer-wild officer-wild-mobile"
                                      : "officer-wild"
                                  }
                                >
                                  <OfficerWild />
                                </div>
                                <div className="text-container">
                                  <div className="small-text">
                                    <Typography>
                                      {t("KnowledgeIsPower")}
                                    </Typography>
                                  </div>
                                  <div className="large-text">
                                    <Typography variant="h5">
                                      {t("TestKnowledge")}
                                    </Typography>

                                  </div>
                                </div>
                                <div className="arrow-container">
                                  <ArrowForwardIosIcon className="arrow-icon" />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>)
              ))}
            </div>
      </div>
    </div >
  );
}
