import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

export let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#18285B"
    },
    secondary: {
      main: "#FFC200"
    }
  },
  typography: {
    fontFamily: [
      '"NexaRegular"'
    ].join(',')
  }
});
theme = responsiveFontSizes(theme);
