import apiService from "../services/api.service";
import * as LanguageActionTypes from "../reducers/language.actionTypes";

export const useGetLanguages = (dispatch: any, regionId: number) => {
  dispatch({ type: LanguageActionTypes.LoadLanguages });
  apiService
    .getLanguages(regionId)
    .then((languages: any) => {
      dispatch({
        type: LanguageActionTypes.LoadLanguagesSuccess,
        payload: languages,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: LanguageActionTypes.LoadLanguagesFail,
        payload: err.message,
      });
    });
};
