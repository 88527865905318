import { useContext, useEffect } from "react";
import { AnswerModel } from "../../models";
import "./CorrectAnswer.component.scss";
import Fab from "@material-ui/core/Fab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMediaQuery } from "react-responsive";
import * as cages from "../../assets/cages";
import check from "../../assets/icons/check-white.svg";
import cup from "../../assets/icons/cup.svg";
import { QuizContext } from "../../reducers/quiz.reducer";
import {
  mobileDeviceResolution,
  tabletDeviceResolution,
} from "../../utils/utils";
import { useTranslation } from "react-i18next";
interface CorrectAnswerProps {
  answers: AnswerModel[];
  onContinueClick: any;
}

const minNumberOfCorrectAnswers = 9;

export function CorrectAnswer(props: CorrectAnswerProps) {
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const isTabletDevice = useMediaQuery({ query: tabletDeviceResolution });
  const quizContext = useContext(QuizContext);
  const { t, i18n } = useTranslation();

  function getCage() {
    switch (quizContext.state.correctAnswers) {
      case 1: {
        return cages.cage1;
      }
      case 2: {
        return cages.cage2;
      }
      case 3: {
        return cages.cage3;
      }
      case 4: {
        return cages.cage4;
      }
      case 5: {
        return cages.cage5;
      }
      case 6: {
        return cages.cage6;
      }
      case 7: {
        return cages.cage7;
      }
      case 8: {
        return cages.cage8;
      }
      case 9: {
        return cages.cage9;
      }
      default : {
        return cages.cage10
      }
    }
  }

  return (
    <div className="correct-answer-root fade-in-container">
      <div className="center-container">
        {quizContext.state.correctAnswers !== minNumberOfCorrectAnswers + 1 ? (
          <div className="details-container">
            <div
              className={
                isMobileDevice || isTabletDevice
                  ? "answer-container mobile-version"
                  : "answer-container"
              }
            >
              <div className="label">{t("Correct")}</div>
              <div
                className={
                  isMobileDevice
                    ? "correct-answer mobile-device"
                    : "correct-answer"
                }
              >
                {props.answers.map((answer) => (
                  <div key={answer.id}>{answer.answer}</div>
                ))}
              </div>
              <div className="checks-container">
                {[...Array(minNumberOfCorrectAnswers + 1)].map(
                  (value, index) => (
                    <div
                      className={
                        index < quizContext.state.correctAnswers
                          ? isMobileDevice
                            ? "checked mobile-version"
                            : "checked"
                          : "not-checked"
                      }
                      key={index}
                    >
                      {index < quizContext.state.correctAnswers ? (
                        <img className="check" src={check} alt="check" />
                      ) : null}
                    </div>
                  )
                )}
              </div>
            </div>
            <div
              className={
                isMobileDevice || isTabletDevice
                  ? "image-container mobile-version"
                  : "image-container"
              }
            >
              <img
                className={
                  isMobileDevice || isTabletDevice
                    ? "cage mobile-version"
                    : "cage"
                }
                src={getCage()}
                alt="cage"
              />
            </div>
          </div>
        ) : (
          <div
            className={
              isMobileDevice || isTabletDevice
                ? "finish-container mobile-version"
                : "finish-container"
            }
          >
            <div
              className={
                isMobileDevice || isTabletDevice
                  ? "info-container mobile-version"
                  : "info-container"
              }
            >
              <div className="cup-container">
                <img className="cup" src={cup} alt="cup" />
              </div>
              <div className="congratulations-text">{t("YouDidIt")}</div>
              <div
                className={
                  isMobileDevice || isTabletDevice
                    ? "text mobile-version"
                    : "text"
                }
              >
                {t("RankingDescription")}
              </div>
            </div>
            <div
              className={
                isMobileDevice || isTabletDevice
                  ? "image-container mobile-version"
                  : "image-container"
              }
            >
              <img
                className={
                  isMobileDevice || isTabletDevice
                    ? "free mobile-version"
                    : "free"
                }
                src={cages.free}
                alt="free"
              />
            </div>
          </div>
        )}
        {!isMobileDevice ? (
          <div className="continue-button-container">
            <Fab
              color="inherit"
              aria-label="add"
              onClick={props.onContinueClick}
            >
              <ExpandMoreIcon />
            </Fab>
            <div className="label">{t("Continue")}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
