import React, { useState, useContext } from "react";

import { Popover, createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import menu from "../../assets/icons/menu.svg";
import earth from "../../assets/icons/earth-white.svg";
import learnmore from "../../assets/icons/learn-more.svg";
import close from "../../assets/icons/close.svg";
import "./MobileMenu.component.scss";
import { useTranslation } from "react-i18next";
import { QuizContext } from "../../reducers/quiz.reducer";
import { pages } from "../../utils/utils";
import { useHistory } from "react-router";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      display: "flex",
    },
    popupContentRoot: {
      height: "100%",
    },
    popupContent: {
      padding: "16px",
      backgroundColor: "#131a42",
      color: "#FFF",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    rankingContainer: {
      display: "flex",
      alignItems: "center",
    },
    learnMoreContainer: {
      display: "flex",
      alignItems: "center",
    },
    closeIconContainer: {
      display: "flex",
      background: "#20264F",
      borderRadius: "5px",
      padding: "20px",
    },
    label: {
      marginLeft: "5px",
    },
  })
);

interface MobileMenuProps {
  onLearnMoreSelected: (event: any) => void;
}

export function MobileMenu(props: MobileMenuProps) {
  const quizContext = useContext(QuizContext);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const ranking = t("Ranking");
  const learnMore = t("LearnMore");
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopup = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  function getMenuStyle() {
    if (quizContext.state.currentQuizPage === pages.wrongAnswer) {
      return "menu wrong-answer";
    } else if (quizContext.state.currentQuizPage === pages.correctAnswer) {
      return "menu correct-answer";
    } else {
      return "menu";
    }
  }

  function onGlobalRankingClick() {
    let path = "ranking";
    history.push(path);
  }

  return (
    <div className="mobile-menu-root-container">
      <img
        className={getMenuStyle()}
        src={menu}
        alt="menu"
        onClick={openPopup}
      />
      <div className={classes.popupContentRoot}>
        <Popover
          classes={{ paper: "customMobileMenuPopupPaper" }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.popupContent}>
            <div
              className={classes.rankingContainer}
              onClick={onGlobalRankingClick}
            >
              <img src={earth} alt="earth" />
              <div className={classes.label}> {ranking}</div>
            </div>
            <div
              className={classes.learnMoreContainer}
              onClick={props.onLearnMoreSelected}
            >
              <img src={learnmore} alt="learn-more" />
              <div className={classes.label}>{learnMore}</div>
            </div>
            <div className={classes.closeIconContainer} onClick={handleClose}>
              <img src={close} alt="close" />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
}
