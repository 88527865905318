import { QuestionModel } from "./question.model";

export class QuizModel {
  id!: number;
  title!: string;
  questions!: QuestionModel[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
