import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { config } from '../config';
import { QuizScoreModel } from "../models/quiz-score.model";
import { BackendParser } from "./backend-parser";

class ApiService {
  private axios: AxiosInstance;
  private backendParser: BackendParser;

  public constructor() {
    this.backendParser = new BackendParser();
    this.axios = axios.create({ responseType: "json" });
  }

  async request(
    method: "GET" | "POST" | "PUT" | "DELETE",
    endpoint: string,
    data?: any
  ) {
    const payload: AxiosRequestConfig = {
      url: `${config.apiUrl}${endpoint}`,
      method: method,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (method === "GET") {
      payload.data = undefined;
    }

    const response = await this.axios.request(payload);
    return response.data;
  }

  getQuizzes(languageId: number) {
    return this.request("GET", `/api/questions/${languageId}`).then(
      (response) => {
        return this.backendParser.parseQuizzes(response);
      }
    );
  }

  getRegions() {
    return this.request("GET", "/api/regions").then((response) => {
      return this.backendParser.parseRegions(response);
    });
  }

  getLanguages(regionId: number) {
    return this.request("GET", `/api/languages/${regionId}`).then(
      (response) => {
        return this.backendParser.parseLanguages(response);
      }
    );
  }

  public getInfoContent(regionId: number, languageId: number) {
    return this.request("GET", `/api/content/${regionId}/${languageId}`).then(
      (response) => {
        return this.backendParser.parseInfoContent(response);
      }
    )
  }

  public async submitQuiz(answers: any[], regionId: number, quizId: number) {
    return await this.request(
      "POST",
      `/api/answers/${regionId}/${quizId}`,
      answers
    );
  }

  public getQuizScore(regionId: number): Promise<QuizScoreModel> {
    return this.request("GET", `/api/results/${regionId}`).then((response) => {
      return this.backendParser.parseQuizScore(response);
    });
  }

  public getRegionsScore(): Promise<Array<QuizScoreModel>> {
    return this.request("GET", "/api/results").then((response) => {
      return this.backendParser.parseRegionsScore(response);
    });
  }
}

export default new ApiService();
