import "./LearnMenu.component.scss";
import React, { useRef, useState } from "react";
import clsx from "clsx";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useEffect, useContext } from "react";
import { ContentContext } from "../../reducers/content.reducer";
import { InfoContentModel } from "../../models/info-content.model";
import { InfoPopup } from "../InfoPopup/InfoPopup.component";
import {
  AppBar,
  CircularProgress,
  Fab,
  SvgIcon,
  Toolbar,
} from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GlobalRanking from "../../assets/icons/earth-white.svg";
import Chapter1Highlight from "../../assets/chapters/chapter1-highlight.png";
import Chapter2Highlight from "../../assets/chapters/chapter2-highlight.png";
import Chapter3Highlight from "../../assets/chapters/chapter3-highlight.png";
import Chapter4Highlight from "../../assets/chapters/chapter4-highlight.png";
import { useTranslation } from "react-i18next";
import { QuizContext } from "../../reducers/quiz.reducer";
import { mobileDeviceResolution, pages } from "../../utils/utils";
import * as QuizActionTypes from "../../reducers/quiz.actionTypes";
import * as ContentActionTypes from "../../reducers/content.actionTypes";
import Icon from "@material-ui/core/Icon";
import { Ranking } from "../Ranking/Ranking.component";
import Backdrop from "@material-ui/core/Backdrop";

const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      overflow: "auto",
      overflowX: "hidden",
      border: 0,
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
      border: "0",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    menuItemsList: {
      height: "100%",
      padding: "0",
    },
    menuItemsListOnMobile: {
      display: "flex",
      maxHeight: "50px",
      padding: "0px",
      paddingTop: "5px",
    },
    menuItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      minHeight: "23%",
      padding: "8px 16px",
      "&:hover": {
        background: "unset",
      },
    },
    menuItemOnMobile: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      padding: "6px",
    },
    menuItemIcon: {
      justifyContent: "center",
      minHeight: "unset",
    },
    menuItemIconOnMobile: {
      justifyContent: "center",
      minHeight: "unset",
      maxHeight: "40px",
    },
    menuImageOnMobile: {
      height: "25px",
    },
    menuItemText: {
      flex: "unset",
      width: "100%",
      whiteSpace: "normal",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "15px",
      color: "#4A4949",
      letterSpacing: "0.23px",
    },
    learnMoreMenuItemText: {
      color: "#FFF",
    },
    menuItemTextOnMobile: {
      fontSize: "12px",
    },
    menuItemTextDark: {
      color: "#000",
      opacity: "0.5",
      margin: "0px",
    },
    menuItemSubtitleSelected: {
      color: theme.palette.secondary.main,
    },

    menuItemSubtitleNotSelected: {
      color: "#DEDEDE",
    },
    menuItemTitleSelected: {
      color: theme.palette.primary.main,
    },
    learnMoreItemTextSelected: {
      color: theme.palette.secondary.main,
    },
    menuItemIconLearnMore: {
      position: "absolute",
      top: "-18%",
    },
    menuItemTextFade: {
      opacity: "0.5",
      flex: "unset",
      width: "100%",
      whiteSpace: "normal",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "13px",
      letterSpacing: "0.23px",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    learnMoreItemOpen: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      minHeight: "8%",
      backgroundColor: "#181c34",
      color: "#FFF",
      padding: "8px 16px",
      "&:hover": {
        backgroundColor: "#181c34",
      },
    },
    iconRoot: {
      height: "100%",
    },
    imageIcon: {
      color: theme.palette.primary.main,
      height: "100%",
      width: "100%",
    },
    loadingIndicator: {
      position: "relative",
      top: "50%",
      left: "50%",
    },
    appBar: {
      top: "auto",
      bottom: 0,
      borderRadius: "10px",
      margin: "0px 5px 5px 5px",
      width: "calc(100% - 10px)",
      backgroundColor: "#FFF",
    },
    grow: {
      flexGrow: 1,
    },
    fabButton: {
      position: "absolute",
      zIndex: 10000,
      top: -30,
      left: 0,
      right: 0,
      margin: "0 auto",
      color: "#FFF",
    },
    standardBorder: {
      borderLeft: "7px solid #FFF",
      borderColor: theme.palette.secondary.main,
    },
    highlightBorder: {
      borderLeft: "7px solid",
      borderColor: theme.palette.secondary.main,
    },
    borderBottom: {
      borderBottom: "dashed 1px #efefef",
    },
    circle: {
      width: "7px",
      height: "7px",
      background: "#d8d8d8",
      borderRadius: "50%",
      position: "absolute",
      bottom: "-4px",
      left: "-4px",
      zIndex: 1,
    },
    openIndicator: {
      height: "14px",
      width: "14px",
      background: "#FFF",
      transform: "rotate(45deg)",
      borderRadius: "3px",
      position: "absolute",
      top: "50%",
      right: "-7px",
    },
    backdrop: {
      zIndex: 100,
      color: "#fff",
    },
    line: {
      position: "absolute",
      left: "50%",
      top: "55%",
    },
    dot: {
      width: "3px",
      height: "3px",
      borderRadius: "50%",
      background: "#d8d8d8",
      marginBottom: "5px",
    },
  })
);

interface LearnMenuProps {
  isOnRankingPage?: boolean;
  selectedElement: HTMLButtonElement | null;
  showStartQuizButton?: boolean;
  onClose: () => void;
  officerWildTakeQuizClick?: () => void;
}

export function LearnMenu(props: LearnMenuProps) {
  const quizContext = useContext(QuizContext);
  const [currentQuizPage, setCurrentQuizPage] = useState(String);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const quizLabel = t("TestYourKnowledge");
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [chapters, setChapters] = React.useState(Array<InfoContentModel>());
  const [isRankingOpened, setIsRankingOpened] = useState(Boolean);
  const [menuElement, setMenuElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [menuSelected, setMenuSelected] =
    React.useState<InfoContentModel | null>(null);
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const { state, dispatch } = useContext(ContentContext);
  const [currentChapterNumber, setCurrentChapterNumber] = useState(0);

  useEffect(() => {
    if (state.content) {
      setChapters(state.content);
    }
  }, [state]);

  useEffect(() => {
    setMenuElement(
      document.getElementById(state.openContentNumber) as HTMLButtonElement
    );
    setMenuSelected(chapters[state.openContentNumber - 1]);
  }, [state.openContentNumber]);

  useEffect(() => {
    if (props.selectedElement) {
      setMenuElement(props.selectedElement);
      setMenuSelected(chapters[4]);
    }
  }, [props]);

  useEffect(() => {
    if (quizContext.state.currentQuizPage) {
      setCurrentQuizPage(quizContext.state.currentQuizPage);
    }
  }, [quizContext.state]);

  useEffect(() => {
    setCurrentChapterNumber(quizContext.state.currentChapterNumber);
  }, [quizContext.state.currentChapterNumber]);

  function onMenuClicked(e: any, chapter: InfoContentModel) {
    setMenuSelected(null);
    setMenuElement(null);
    setMenuSelected(chapter);
    setMenuElement(e.currentTarget);
  }

  function onLeftMenuClick(e: any) {}

  function onPopupClose() {
    setMenuSelected(null);
    setMenuElement(null);
    setIsRankingOpened(false);
    dispatch?.({
      type: ContentActionTypes.SetOpenContentNumber,
      payload: null,
    });
    props.onClose();
    if (currentQuizPage === pages.detailedReportPage) {
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCurrentQuizPage,
        payload: pages.submitPage,
      });
    }
  }

  function onTakeQuizClick() {
    if (quizContext.state.currentQuizPage !== pages.quizInfoPage) {
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCurrentQuizPage,
        payload: pages.quizInfoPage,
      });
    } else {
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCurrentQuizPage,
        payload: pages.questionPage,
      });
      quizContext.dispatch?.({
        type: QuizActionTypes.SetStartTime,
        payload: new Date(),
      });
    }
  }

  function onShowResultClick() {
    if (quizContext.state.isSelectedAnswer) {
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCurrentQuizPage,
        payload: pages.resultPage,
      });
    }
  }

  function onShowNextQuestionClick(e: any) {
    if (currentQuizPage !== pages.submitPage && !props.isOnRankingPage) {
      if (!quizContext.state.isEndOfChapter) {
        quizContext.dispatch?.({
          type: QuizActionTypes.SetCurrentQuizPage,
          payload: pages.questionPage,
        });
      } else {
        quizContext.dispatch?.({
          type: QuizActionTypes.SetCurrentQuizPage,
          payload: pages.chapterEndPage,
        });
      }
    } else {
      setIsRankingOpened(true);
      setMenuElement(e.currentTarget);
    }
  }

  function getMainButtonStyle() {
    if (
      quizContext.state.currentQuizPage === pages.submitPage ||
      props.isOnRankingPage
    ) {
      return "global-ranking";
    } else if (quizContext.state.currentQuizPage === pages.quizInfoPage) {
      return "secondary";
    } else if (quizContext.state.currentQuizPage === pages.wrongAnswer) {
      return "wrong-answer";
    } else if (quizContext.state.currentQuizPage === pages.correctAnswer) {
      return "correct-answer";
    } else if (quizContext.state.isSelectedAnswer) {
      return "submit";
    } else {
      return "primary";
    }
  }

  function onOfficerWildTakeQuizClick() {
    onPopupClose();
    if (props.officerWildTakeQuizClick) {
      props.officerWildTakeQuizClick();
    }
  }

  function getStyleBasedOnCurrentChapter(chapterId: number) {
    if (currentChapterNumber === 0) {
      return classes.highlightBorder;
    } else {
      if (
        chapterId <= currentChapterNumber ||
        (chapterId === 5 && currentChapterNumber === 4)
      ) {
        return classes.highlightBorder;
      }
      if (chapterId === 5) {
        return "";
      }
    }

    return classes.standardBorder;
  }

  function getBorderBottomBasedOnChapter(chapterId: number) {
    if (chapterId < 4) {
      return classes.borderBottom;
    }
  }

  function getBorderBottomCircleBasedOnChapter(chapterId: number) {
    if (chapterId < 4) {
      return classes.circle;
    }
  }

  function getHighlighIconBasedOnChapter(chapterId: number) {
    const chapterIndex = getSelectedChapterIndex(chapterId);
    switch (chapterIndex) {
      case 0:
        return Chapter1Highlight;
      case 1:
        return Chapter2Highlight;
      case 2:
        return Chapter3Highlight;
      case 3:
        return Chapter4Highlight;
    }
  }

  function getSelectedChapterIndex(chapterId: number) {
    return chapters.findIndex((chapter) => chapter.id === chapterId);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {isMobileDevice && props.showStartQuizButton ? (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <List className={classes.menuItemsListOnMobile}>
              {chapters && chapters.length
                ? chapters.map((chapter, index) => {
                    if (index === 0 || index === 1) {
                      return (
                        <ListItem
                          id={state.openContentNumber}
                          className={classes.menuItemOnMobile}
                          button
                          key={chapter.title}
                          onClick={(event) => onMenuClicked(event, chapter)}
                        >
                          <ListItemIcon
                            className={classes.menuItemIconOnMobile}
                          >
                            <img
                              className={classes.menuImageOnMobile}
                              src={
                                chapter.id === menuSelected?.id
                                  ? getHighlighIconBasedOnChapter(chapter.id)
                                  : chapter.icon
                              }
                              alt={chapter.title}
                            ></img>
                          </ListItemIcon>
                          {open ? (
                            <>
                              <ListItemText
                                className={
                                  "customPrimaryText " +
                                  `${classes.menuItemText} ${
                                    isMobileDevice
                                      ? classes.menuItemTextDark
                                      : ""
                                  } ${
                                    chapter.id === menuSelected?.id
                                      ? classes.menuItemSubtitleSelected
                                      : ""
                                  }`
                                }
                                primary={chapter.abbr}
                              />
                            </>
                          ) : null}
                        </ListItem>
                      );
                    }
                  })
                : null}
            </List>
            {(!currentQuizPage ||
              menuSelected ||
              isRankingOpened ||
              currentQuizPage === pages.detailedReportPage) &&
            (!props.isOnRankingPage || menuSelected || isRankingOpened) ? (
              <div>
                {!menuSelected &&
                !isRankingOpened &&
                currentQuizPage !== pages.detailedReportPage ? (
                  <div className="quiz-button-label">{quizLabel}</div>
                ) : null}
                <Fab
                  color={
                    menuSelected || isRankingOpened ? "secondary" : "primary"
                  }
                  aria-label="add"
                  className={classes.fabButton}
                  onClick={
                    menuSelected ||
                    isRankingOpened ||
                    currentQuizPage === pages.detailedReportPage
                      ? () => onPopupClose()
                      : () => onTakeQuizClick()
                  }
                >
                  {menuSelected ||
                  isRankingOpened ||
                  currentQuizPage === pages.detailedReportPage ? (
                    <CloseIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </Fab>
              </div>
            ) : (
              <div className={getMainButtonStyle()}>
                {currentQuizPage === pages.quizInfoPage ? (
                  <div className="take-quiz-button-label">
                    {t("TakeTheQuiz")}
                  </div>
                ) : null}
                <Fab
                  color="inherit"
                  aria-label="add"
                  className={classes.fabButton}
                  onClick={
                    currentQuizPage === pages.questionPage
                      ? () => onShowResultClick()
                      : currentQuizPage === pages.quizInfoPage
                      ? () => onTakeQuizClick()
                      : (event) => onShowNextQuestionClick(event)
                  }
                >
                  {currentQuizPage === pages.submitPage ||
                  props.isOnRankingPage ? (
                    <Icon>
                      <img src={GlobalRanking} />
                    </Icon>
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </Fab>
              </div>
            )}
            <div className={classes.grow} />
            <div className={classes.line}>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
            </div>
            <List className={classes.menuItemsListOnMobile}>
              {chapters && chapters.length
                ? chapters.map((chapter, index) => {
                    if (index === 2 || index === 3) {
                      return (
                        <ListItem
                          id={state.openContentNumber}
                          className={classes.menuItemOnMobile}
                          button
                          key={chapter.title}
                          onClick={(event) => onMenuClicked(event, chapter)}
                        >
                          <ListItemIcon
                            className={classes.menuItemIconOnMobile}
                          >
                            <img
                              className={classes.menuImageOnMobile}
                              src={
                                chapter.id === menuSelected?.id
                                  ? getHighlighIconBasedOnChapter(chapter.id)
                                  : chapter.icon
                              }
                              alt={chapter.title}
                            ></img>
                          </ListItemIcon>
                          {open ? (
                            <>
                              <ListItemText
                                className={
                                  "customPrimaryText " +
                                  `${classes.menuItemText} ${
                                    isMobileDevice
                                      ? classes.menuItemTextDark
                                      : ""
                                  } ${
                                    chapter.id === menuSelected?.id
                                      ? classes.menuItemSubtitleSelected
                                      : ""
                                  }`
                                }
                                primary={chapter.abbr}
                              />
                            </>
                          ) : null}
                        </ListItem>
                      );
                    }
                  })
                : null}
            </List>
          </Toolbar>
        </AppBar>
      ) : !isMobileDevice ? (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
            }),
          }}
          onClick={onLeftMenuClick}
        >
          <List className={classes.menuItemsList}>
            {chapters && chapters.length ? (
              chapters.map((chapter, index) => {
                if (index !== 4) {
                  return (
                    <ListItem
                      id={state.openContentNumber}
                      className={`${
                        classes.menuItem
                      } ${getStyleBasedOnCurrentChapter(
                        chapter.id
                      )} ${getBorderBottomBasedOnChapter(chapter.id)}`}
                      button
                      key={chapter.title}
                      onClick={(event) => onMenuClicked(event, chapter)}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        <img
                          src={
                            chapter.id === menuSelected?.id
                              ? getHighlighIconBasedOnChapter(chapter.id)
                              : chapter.icon
                          }
                          alt={chapter.title}
                        ></img>
                      </ListItemIcon>

                      {open ? (
                        <>
                          <ListItemText
                            className={`${classes.menuItemTextFade} ${
                              chapter.id === menuSelected?.id
                                ? classes.menuItemSubtitleSelected
                                : classes.menuItemSubtitleNotSelected
                            }`}
                            primary={chapter.subtitle}
                          />
                          <ListItemText
                            className={`${classes.menuItemText} ${
                              chapter.id === menuSelected?.id
                                ? classes.menuItemTitleSelected
                                : null
                            }`}
                            primary={chapter.title}
                          />
                        </>
                      ) : null}
                      {chapter.id === menuSelected?.id ? (
                        <div className={classes.openIndicator}></div>
                      ) : null}
                      <div
                        className={getBorderBottomCircleBasedOnChapter(
                          chapter.id
                        )}
                      ></div>
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem
                      id={state.openContentNumber}
                      className={`${
                        classes.learnMoreItemOpen
                      } ${getStyleBasedOnCurrentChapter(chapter.id)}`}
                      button
                      key={chapter.title}
                      onClick={(event) => onMenuClicked(event, chapter)}
                    >
                      <ListItemIcon
                        className={`${classes.menuItemIcon} ${classes.menuItemIconLearnMore}`}
                      >
                        <img src={chapter.icon} alt={chapter.title}></img>
                      </ListItemIcon>
                      {open ? (
                        <>
                          <ListItemText
                            className={`${classes.menuItemText}  ${
                              chapter.id === menuSelected?.id
                                ? classes.learnMoreItemTextSelected
                                : classes.learnMoreMenuItemText
                            }`}
                            primary={chapter.title}
                          />
                        </>
                      ) : null}
                    </ListItem>
                  );
                }
              })
            ) : (
              <CircularProgress className={classes.loadingIndicator} />
            )}
          </List>
        </Drawer>
      ) : null}

      {menuElement && menuSelected && !isRankingOpened ? (
        <>
          <Backdrop
            className={classes.backdrop}
            open={menuElement ? true : false}
          >
            <InfoPopup
              selectedChapterElement={menuElement}
              selectedContent={menuSelected}
              isOnMobileDevice={isMobileDevice}
              openIndicatorPosition={
                getSelectedChapterIndex(menuSelected.id) + 1
              }
              hasQuizShortcut={menuSelected.id !== 5}
              onClose={onPopupClose}
              officerWildTakeQuizClick={onOfficerWildTakeQuizClick}
            ></InfoPopup>
          </Backdrop>
          {!isMobileDevice ? (
            <div className="close-button-container fade-in-container">
              <Fab
                color="inherit"
                aria-label="close"
                size="medium"
                onClick={onPopupClose}
              >
                <CloseIcon />
              </Fab>
            </div>
          ) : null}
        </>
      ) : null}
      {isRankingOpened && menuElement ? (
        <Ranking element={menuElement} onClose={onPopupClose} />
      ) : null}
    </div>
  );
}
