import "./QuizDescription.component.scss";
import officerWild from "../../assets/officer-wild.svg";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import globalRanking from "../../assets/icons/global-ranking.svg";
import questionsAndChapters from "../../assets/icons/questions-and-chapters.svg";
import freeAnimal from "../../assets/icons/set-animal-free.svg";
import quizInfoArrow from "../../assets/icons/quiz-info-arrow.svg";
import quizInfoArrowMobile from "../../assets/icons/quiz-info-arrow-mobile.svg";
import { useMediaQuery } from "react-responsive";
import { mobileDeviceResolution } from "../../utils/utils";

export function QuizDescription() {
  const { t, i18n } = useTranslation();
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  return (
    <div
      className={
        !isMobileDevice
          ? "quiz-description-root-container fade-in-container"
          : "quiz-description-root-container fade-in-container mobile-version"
      }
    >
      <div
        className={
          !isMobileDevice ? "info-container" : "info-container mobile-version"
        }
      >
        <div
          className={
            !isMobileDevice ? "info-content" : "info-content mobile-version"
          }
        >
          <div className="title-container">
            <Typography
              className={!isMobileDevice ? "title" : "title mobile-version"}
            >
              {t("ReadyToJoinTheFight")}
            </Typography>
          </div>
          <div
            className={!isMobileDevice ? "content" : "content mobile-version"}
          >
            <div
              className={
                !isMobileDevice ? "quiz-info" : "quiz-info mobile-version"
              }
            >
              {t("QuizInfo")}
            </div>
            <div
              className={
                !isMobileDevice
                  ? "informations-root"
                  : "informations-root mobile-version"
              }
            >
              <div
                className={
                  !isMobileDevice
                    ? "icons-container"
                    : "icons-container mobile-version"
                }
              >
                <img
                  className={isMobileDevice ? "icon-mobile-version" : "icon-desktop-version"}
                  src={questionsAndChapters}
                  alt="Questions and chapters"
                />
                <hr
                  className={!isMobileDevice ? "line" : "line mobile-version"}
                ></hr>
                <img
                  src={isMobileDevice ? quizInfoArrowMobile : quizInfoArrow}
                  alt="Quiz info arrow"
                />
                <hr
                  className={!isMobileDevice ? "line" : "line mobile-version"}
                ></hr>
                <img
                  className={isMobileDevice ? "icon-mobile-version" : "icon-desktop-version"}
                  src={freeAnimal}
                  alt="Free animal"
                />
                <hr
                  className={!isMobileDevice ? "line" : "line mobile-version"}
                ></hr>
                <img
                  src={isMobileDevice ? quizInfoArrowMobile : quizInfoArrow}
                  alt="Quiz info arrow"
                />
                <hr
                  className={!isMobileDevice ? "line" : "line mobile-version"}
                ></hr>
                <img
                  className={isMobileDevice ? "icon-mobile-version" : "icon-desktop-version"}
                  src={globalRanking}
                  alt="Global Ranking"
                />
              </div>
              <div
                className={
                  !isMobileDevice
                    ? "informations-container"
                    : "informations-container mobile-version"
                }
              >
                <div
                  className={
                    !isMobileDevice
                      ? "quiz-info-content"
                      : "quiz-info-content mobile-version"
                  }
                >
                  <div
                    className={
                      !isMobileDevice ? "title" : "title mobile-version"
                    }
                  >
                    {t("QuestionsAndChapters")}
                  </div>
                  <div
                    className={
                      !isMobileDevice
                        ? "information"
                        : "information mobile-version"
                    }
                  >
                    {t("QuestionsAndChaptersInfo")}
                  </div>
                </div>
                <div
                  className={
                    !isMobileDevice
                      ? "quiz-info-content"
                      : "quiz-info-content mobile-version"
                  }
                >
                  <div
                    className={
                      !isMobileDevice ? "title" : "title mobile-version"
                    }
                  >
                    {t("SetYourAnimalFree")}
                  </div>
                  <div
                    className={
                      !isMobileDevice
                        ? "information"
                        : "information mobile-version"
                    }
                  >
                    {t("SetYourAnimalFreeInfo")}
                  </div>
                </div>
                <div
                  className={
                    !isMobileDevice
                      ? "quiz-info-content"
                      : "quiz-info-content mobile-version"
                  }
                >
                  <div
                    className={
                      !isMobileDevice ? "title" : "title mobile-version"
                    }
                  >
                    {t("GlobalRankingQuizInfoLabel")}
                  </div>
                  <div
                    className={
                      !isMobileDevice
                        ? "information"
                        : "information mobile-version"
                    }
                  >
                    {t("GlobalRankingQuizInfo")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            !isMobileDevice ? (
              <div className="bubble-triangle"></div>
            ): null
          }
        </div>
      </div>
      <div
        className={
          !isMobileDevice
            ? "officer-wild-container"
            : "officer-wild-container mobile-version"
        }
      >
        <img
          className={!isMobileDevice ? "officer" : "officer mobile-version"}
          src={officerWild}
          alt="officer-wild"
        />
      </div>
    </div>
  );
}
