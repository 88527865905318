import * as React from "react";
import "./OfficerWild.component.scss";
import officerWild from "../../assets/officer-wild.svg";
import { Typography } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import { mobileDeviceResolution } from "../../utils/utils";
import { useTranslation } from 'react-i18next';

interface OfficerWildProps {
  title?: string;
  content?: string;
  largeSize?: boolean;
  image?: { image: any; name: string };
  displayOfficerUnderContent?: boolean;
  onReadMoreClick?: any;
  hasReadMoreButton?: boolean;
}

export function OfficerWild(props: OfficerWildProps) {
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const { t, i18n } = useTranslation();

  function getImageStyle() {
    if (props.image?.name === "snake") {
      return "snake";
    }
    if (props.image?.name === "fingerprint") {
      return "fingerprint";
    }
    if (props.image?.name === "europe") {
      return "europe";
    }
  }
  return (
    <div className="officer-wild-root-container fade-in-officer-wild">
      {!props.displayOfficerUnderContent ? (
        <div className="officer-wild-on-right-side">
          {props.title && props.content ? (
            <div className="info-container">
              <div className="text-container">
                <div className="title-container">
                  <Typography
                    variant={props.largeSize ? "h2" : "h4"}
                    className="title"
                  >
                    {props.title}
                  </Typography>
                </div>
                <div className="content-container">
                  <Typography
                    variant={props.largeSize ? "h5" : "inherit"}
                    className="content"
                  >
                    {props.content}
                  </Typography>
                </div>
                {props.image ? (
                  <img
                    className={getImageStyle()}
                    src={props.image.image}
                    alt="image"
                  ></img>
                ) : null}
              </div>
              <div className="bubble-triangle"></div>
            </div>
          ) : null}
          <div className="officer-wild-container">
            <img className="officer" src={officerWild} alt="officer-wild" />
          </div>
        </div>
      ) : (
        <div className="officer-wild-under-content">
          {props.title && props.content ? (
            <div className="info-container">
              <div className="text-container">
                <div className="title-container">
                  <Typography
                    variant={props.largeSize ? "h2" : "h4"}
                    className={
                      !isMobileDevice ? "title" : "title mobile-version"
                    }
                  >
                    {props.title}
                  </Typography>
                </div>
                <div className="content-container">
                  <Typography className="content">{props.content}</Typography>
                  {props.hasReadMoreButton ? (
                    <button
                      className="read-more-button"
                      onClick={props.onReadMoreClick}
                    >
                      {t("ReadMoreMobile")}
                    </button>
                  ) : null}
                </div>
                {props.image ? (
                  <img
                    className={getImageStyle()}
                    src={props.image.image}
                    alt="image"
                  ></img>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="officer-wild-container">
            <img className="officer" src={officerWild} alt="officer-wild" />
          </div>
        </div>
      )}
    </div>
  );
}
