import React, { useEffect, useContext } from "react";
import "./RegionSelection.scss";
import { RegionContext } from "../../reducers/region.reducer";
import { useGetRegions } from "../../hooks/useGetRegions";
import { RegionAndFlagList } from "../../components/RegionAndFlagList/RegionAndFlagList.component";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { mobileDeviceResolution } from "../../utils/utils";
import { QuizContext } from '../../reducers/quiz.reducer';
import * as QuizActionTypes from "../../reducers/quiz.actionTypes";

export function RegionSelectionPage() {
  const { t, i18n } = useTranslation();
  const title = t("ChooseRegion");
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const quizContext = useContext(QuizContext);

  const { state, dispatch } = useContext(RegionContext);

  useEffect(() => {
    GetRegions();
    quizContext.dispatch?.({
      type: QuizActionTypes.SetCurrentQuizPage,
      payload: null,
    });
    quizContext.dispatch?.({
      type: QuizActionTypes.ClearCorrectAnswers,
    });
    quizContext.dispatch?.({
      type: QuizActionTypes.SetCurrentChapterNumber,
      payload: 0,
    });
  }, []);

  function GetRegions() {
    useGetRegions(dispatch);
  }

  return (
    <div
      className={
        isMobileDevice
          ? "region-selection-page-root-container mobile-version"
          : "region-selection-page-root-container"
      }
    >
      <div
        className={
          isMobileDevice
            ? "selection-container mobile-version"
            : "selection-container"
        }
      >
        <div
          className={
            isMobileDevice
              ? "title-container mobile-version"
              : "title-container"
          }
        >
          {title}
        </div>
        <div className="regions-columns">
          <RegionAndFlagList />
        </div>
      </div>
    </div>
  );
}
