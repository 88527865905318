import apiService from "../services/api.service";
import * as RegionActionTypes from "../reducers/region.actionTypes";

export const useGetRegions = (dispatch: any) => {
  dispatch({ type: RegionActionTypes.LoadRegions });
  apiService
    .getRegions()
    .then((regions: any) => {
      dispatch({
        type: RegionActionTypes.LoadRegionsSuccess,
        payload: regions,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: RegionActionTypes.LoadRegionsFail,
        payload: err.message,
      });
    });
};
