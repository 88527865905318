import apiService from "../services/api.service";
import * as QuizActionTypes from "../reducers/quiz.actionTypes";

export const useGetQuizzes = (dispatch: any, languageId: number) => {
  dispatch({ type: QuizActionTypes.LoadQuizzes });
  apiService
    .getQuizzes(languageId)
    .then((quizzes: any) => {
      dispatch({
        type: QuizActionTypes.LoadQuizzesSuccess,
        payload: quizzes,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: QuizActionTypes.LoadQuizzesFail,
        payload: err.message,
      });
    });
};
