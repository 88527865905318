import { SectionModel } from "./section.model";

export class InfoContentModel {
  id!: number;
  title!: string;
  subtitle!: string;
  content!: string;
  regionId!: number;
  region!: string;
  icon!: string;
  language!: string;
  sections!: SectionModel[];
  abbr!: string;
  order!: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
