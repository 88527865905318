import * as React from "react";
import * as ContentActionTypes from "./content.actionTypes";

const initialState = {
  content: null,
  loadingContent: false,
  error: null,
  openContentNumber: null,
};

const ContentContext = React.createContext<{
  state?: any;
  dispatch?: React.Dispatch<any>;
}>({});

const contentReducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case ContentActionTypes.SetOpenContentNumber:
      return {
        ...state,
        openContentNumber: action.payload,
      };
    case ContentActionTypes.LoadContent:
      return {
        ...state,
        content: null,
        loadingContent: true,
      };
    case ContentActionTypes.LoadContentSuccess:
      return {
        ...state,
        content: action.payload,
        loadingContent: false,
      };
    case ContentActionTypes.LoadContentFail:
      return {
        loadingContent: false,
        error: action.payload,
      };

    case ContentActionTypes.ClearContent:
      return initialState;

    default:
      return {
        ...state,
      };
  }
};

const ContentProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(contentReducer, initialState);
  const value = { state, dispatch };
  return (
    <ContentContext.Provider value={value}>
      {props.children}
    </ContentContext.Provider>
  );
};

export { ContentContext, ContentProvider };
