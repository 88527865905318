import apiService from "../services/api.service";
import * as QuizActionTypes from "../reducers/quiz.actionTypes";
import { QuizScoreModel } from '../models/quiz-score.model';

export const useGetInitialQuizScore = (dispatch: any, regionId: number) => {
  dispatch({ type: QuizActionTypes.LoadInitialQuizScore });
  apiService
    .getQuizScore(regionId)
    .then((score: QuizScoreModel) => {
      dispatch({
        type: QuizActionTypes.LoadInitialQuizScoreSuccess,
        payload: score,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: QuizActionTypes.LoadInitialQuizScoreFail,
        payload: err.message,
      });
    });
};
