import { AnswerModel } from "./answer.model";

export class QuestionModel {
  id!: number;
  position!: number;
  question!: string;
  description!: string;
  type!: string;
  answers!: AnswerModel[];
  chapter!: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
