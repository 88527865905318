import apiService from "../services/api.service";
import * as QuizActionTypes from "../reducers/quiz.actionTypes";
import { QuizScoreModel } from '../models/quiz-score.model';

export const useGetGlobalQuizScore = (dispatch: any) => {
  dispatch({ type: QuizActionTypes.LoadGlobalQuizScore });
  apiService
    .getQuizScore(0)
    .then((score: QuizScoreModel) => {
      dispatch({
        type: QuizActionTypes.LoadGlobalQuizScoreSuccess,
        payload: score,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: QuizActionTypes.LoadGlobalQuizScoreFail,
        payload: err.message,
      });
    });
};
