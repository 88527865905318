import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { createStyles, Popover } from "@material-ui/core";
import "./InfoPopup.component.scss";
import { ChapterSections } from "../ChapterSections/ChapterSections.component";
import { InfoContentModel } from "../../models/info-content.model";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      display: "flex",
    },
    popupContentRoot: {
      height: "100%",
    },
    popupContentRootMobile: {
      height: "100%",
      zIndex: 500,
    },
    popupContent: {
      padding: "24px",
      backgroundColor: "#191E48",
      color: "#FFF",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    customPopupPaper: {
      color: "red",
    },
  })
);

interface InfoPopupProps {
  selectedChapterElement: HTMLButtonElement;
  selectedContent: InfoContentModel;
  openIndicatorPosition: number;
  isOnMobileDevice: boolean;
  hasQuizShortcut: boolean;
  onClose: () => void;
  officerWildTakeQuizClick: () => void;
}

export function InfoPopup(props: InfoPopupProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    props.selectedChapterElement
  );

  const handleClose = () => {
    setAnchorEl(null);
    props.onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function getSelectedChapterStyle() {
    if(props.openIndicatorPosition === 5) {
      return {'dispaly': 'none'}
    } else {
      return { 'top': `${11.5 * (props.openIndicatorPosition * 2 - 1)}%`};
    }
  }

  return (
    <div
      className={
        props.isOnMobileDevice
          ? classes.popupContentRootMobile
          : classes.popupContentRoot
      }
    >
      <Popover
        marginThreshold={0}
        classes={
          !props.isOnMobileDevice
            ? { paper: "customPopupPaper" }
            : { paper: "customPopupPaperMobile" }
        }
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: props.isOnMobileDevice ? "center" : "right",
        }}
        transformOrigin={{
          vertical: props.isOnMobileDevice ? "bottom" : "top",
          horizontal: props.isOnMobileDevice ? "center" : "left",
        }}
      >
        {
          props.openIndicatorPosition!== 5 ?
          (
            <div className="open-indicator" style={getSelectedChapterStyle()}></div>
          ): null
        }
        <ChapterSections
          content={props.selectedContent}
          displayOfficerWild={props.hasQuizShortcut}
          officerWildClick={props.officerWildTakeQuizClick}
        ></ChapterSections>
      </Popover>
    </div>
  );
}
