export enum pages {
  questionPage = "QUESTION_PAGE",
  resultPage = "RESULT_PAGE",
  correctAnswer = "CORRECT_ANSWER",
  wrongAnswer = "WRONG_ANSWER",
  submitPage = "SUBMIT_PAGE",
  chapterEndPage = " CHAPTER_END_PAGE",
  detailedReportPage = "DETAILED_REPORT_PAGE",
  rankingPage = "RANKING_PAGE",
  quizInfoPage = "QUIZ_INFO_PAGE",
}

export const mobileDeviceResolution = "(max-width: 550px)";
export const tabletDeviceResolution = "(max-width: 1024px)";
