import { useContext, useEffect, useState } from "react";
import { OfficerWild } from "../../components/OfficerWild/OfficerWild.component";
import { Ranking } from "../../components/Ranking/Ranking.component";
import "./Ranking.page.scss";
import { useGetGlobalQuizScore } from "../../hooks/useGetGlobalQuizScore";
import { useGetRegionsScore } from "../../hooks/useGetRegionsScore";
import { QuizScoreModel } from "../../models/quiz-score.model";
import { QuizContext } from "../../reducers/quiz.reducer";
import * as rankingProgress from "../../assets/ranking";
import { LanguageSelection } from "../../components/LanguageSelection/LanguageSelection.component";
import { useMediaQuery } from "react-responsive";
import { mobileDeviceResolution, pages } from "../../utils/utils";
import euFlag from "../../assets/icons/eu-flag.svg";
import cup from "../../assets/icons/cup-yellow.svg";
import cupWhite from "../../assets/icons/cup.svg";
import { MobileMenu } from "../../components/MobileMenu/MobileMenu.component";
import React from "react";
import { LearnMenu } from "../../components/LearnMenu/LearnMenu.component";
import questionMark from "../../assets/icons/question-mark-white.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as QuizActionTypes from "../../reducers/quiz.actionTypes";
import { RegionContext } from "../../reducers/region.reducer";

export function RankingPage() {
  const quizContext = useContext(QuizContext);
  const [globalScore, setGlobalScore] = useState(new QuizScoreModel());
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { state, dispatch } = useContext(RegionContext);

  const [menuElement, setMenuElement] =
    React.useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!state.selectedRegion) {
      history.push("/");
    }
  }, [state]);

  useEffect(() => {
    GetGlobalQuizScore();
    quizContext.dispatch?.({
      type: QuizActionTypes.SetCurrentQuizPage,
      payload: pages.rankingPage,
    });
  }, []);

  useEffect(() => {
    setGlobalScore(quizContext.state.globalScore);
  }, [quizContext.state.globalScore]);

  function onLearnMoreSelected(event: any) {
    setMenuElement(event.currentTarget);
  }

  function GetRankingProgressImage() {
    switch (true) {
      case globalScore?.contributionPercentage < 10: {
        return rankingProgress.ranking0;
      }
      case globalScore?.contributionPercentage < 20: {
        return rankingProgress.ranking10;
      }
      case globalScore?.contributionPercentage < 30: {
        return rankingProgress.ranking20;
      }
      case globalScore?.contributionPercentage < 40: {
        return rankingProgress.ranking30;
      }
      case globalScore?.contributionPercentage < 50: {
        return rankingProgress.ranking40;
      }
      case globalScore?.contributionPercentage < 60: {
        return rankingProgress.ranking50;
      }
      case globalScore?.contributionPercentage < 70: {
        return rankingProgress.ranking60;
      }
      case globalScore?.contributionPercentage < 80: {
        return rankingProgress.ranking70;
      }
      case globalScore?.contributionPercentage < 90: {
        return rankingProgress.ranking80;
      }
      case globalScore?.contributionPercentage < 100: {
        return rankingProgress.ranking90;
      }
      case globalScore?.contributionPercentage == 100: {
        return rankingProgress.ranking100;
      }
    }
  }

  function GetRankingProgressImageOnMobile() {
    switch (true) {
      case globalScore?.contributionPercentage < 10: {
        return rankingProgress.mobileRanking0;
      }
      case globalScore?.contributionPercentage < 20: {
        return rankingProgress.mobileRanking10;
      }
      case globalScore?.contributionPercentage < 30: {
        return rankingProgress.mobileRanking20;
      }
      case globalScore?.contributionPercentage < 40: {
        return rankingProgress.mobileRanking30;
      }
      case globalScore?.contributionPercentage < 50: {
        return rankingProgress.mobileRanking40;
      }
      case globalScore?.contributionPercentage < 60: {
        return rankingProgress.mobileRanking50;
      }
      case globalScore?.contributionPercentage < 70: {
        return rankingProgress.mobileRanking60;
      }
      case globalScore?.contributionPercentage < 80: {
        return rankingProgress.mobileRanking70;
      }
      case globalScore?.contributionPercentage < 90: {
        return rankingProgress.mobileRanking80;
      }
      case globalScore?.contributionPercentage < 100: {
        return rankingProgress.mobileRanking90;
      }
      case globalScore?.contributionPercentage == 100: {
        return rankingProgress.mobileRanking100;
      }
    }
  }

  function GetGlobalQuizScore() {
    useGetGlobalQuizScore(quizContext.dispatch);
  }

  function onClose() {
    setMenuElement(null);
  }

  function onBackToQuizClick() {
    let path = "campaign";
    history.push(path);
    quizContext.dispatch?.({
      type: QuizActionTypes.SetCurrentQuizPage,
      payload: null,
    });
    quizContext.dispatch?.({
      type: QuizActionTypes.ClearCorrectAnswers,
    });
    quizContext.dispatch?.({
      type: QuizActionTypes.SetCurrentChapterNumber,
      payload: 0,
    });
  }

  return (
    <div
      className={
        isMobileDevice
          ? "ranking-page-root-container mobile-device"
          : "ranking-page-root-container"
      }
    >
      {!isMobileDevice ? (
        <div className="laptop-device-container">
          <div className="left-container">
            <Ranking />
          </div>
          <div className="image-container">
            <div
              className={
                isMobileDevice
                  ? "language-selection mobile-version"
                  : "language-selection"
              }
            >
              <div className="progresive-bar-container">
                <img className="cup" src={cup} alt="cup" />
                <div
                  className="progress"
                  style={{ width: globalScore?.contributionPercentage + "%" }}
                >
                  {globalScore?.contributionPercentage}%
                  <img className="eu-flag" src={euFlag} alt="euFlag" />
                </div>
              </div>
              <LanguageSelection isOnGlobalRanking={true} />
            </div>
            <div className="officer-container">
              <OfficerWild
                largeSize={false}
                title={t("OfficerWildRankingTitle")}
                content={t("OfficerWildRankingContent")}
                displayOfficerUnderContent={true}
              />
            </div>
            <img
              className="ranking-progress"
              src={GetRankingProgressImage()}
              alt="rankingProgress"
            />
          </div>
        </div>
      ) : (
        <div className="mobile-device-container">
          <div className="menu-container">
            <div
              className="question-mark-container"
              onClick={onBackToQuizClick}
            >
              <img
                className="question-mark"
                src={questionMark}
                alt="questionMark"
              />
              <div className="label">{t("BackToQuiz")}</div>
            </div>
            <MobileMenu onLearnMoreSelected={onLearnMoreSelected} />
          </div>
          {globalScore?.contributionPercentage === 100 ? (
            <div className="top-container">
              <div className="icon-container">
                <div className="cup-container">
                  <img className="cup" src={cupWhite} alt="cup" />
                </div>
                <div className="label">{t("WeDidIt")}</div>
              </div>
              <div className="text-container">{t("RankingDescription")}</div>
            </div>
          ) : null}
          <div
            className={
              globalScore?.contributionPercentage < 100
                ? "image-container"
                : "image-container-complete"
            }
          >
            <img
              className="ranking-progress"
              src={GetRankingProgressImageOnMobile()}
              alt="rankingProgress"
            />
          </div>
          {globalScore?.contributionPercentage < 100 ? (
            <div className="progressive-bar-root">
              <div className="progresive-bar-container">
                <img className="cup" src={cup} alt="cup" />
                <div
                  className="progress"
                  style={{ width: globalScore?.contributionPercentage + "%" }}
                >
                  <img className="eu-flag" src={euFlag} alt="euFlag" />
                </div>
              </div>
              <div className="progress-number-container">
                <div
                  className="progress-number"
                  style={{ width: globalScore?.contributionPercentage + "%" }}
                >
                  {globalScore?.contributionPercentage}%
                </div>
              </div>
            </div>
          ) : null}
          <LearnMenu
            isOnRankingPage={true}
            showStartQuizButton={true}
            selectedElement={menuElement}
            onClose={onClose}
          />
        </div>
      )}
    </div>
  );
}
