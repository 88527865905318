export class QuizScoreModel {
  regionId!: number;
  regionName!: string;
  correctPct!: string;
  count!: number;
  icon!: string;
  contributionPercentage!: number;
  contribution!: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
