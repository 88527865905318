import React, { useContext, useEffect, useState } from "react";
import { RegionModel } from "../../models/region.model";
import "./LanguageSelection.component.scss";
import earth from "../../assets/icons/earth.svg";
import arrow from "../../assets/icons/arrow.svg";
import questionMark from "../../assets/icons/question-mark.svg";
import languageSelection from "../../assets/icons/language-selection.svg";
import { RegionAndFlag } from "../RegionAndFlag/RegionAndFlag.component";
import { RegionContext } from "../../reducers/region.reducer";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../reducers/language.reducer";
import { useGetLanguages } from "../../hooks/useGetLanguages";
import { Popover, createStyles, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LanguageModel } from "../../models";
import * as LanguageActionTypes from "../../reducers/language.actionTypes";
import { useHistory } from "react-router-dom";
import { ContentContext } from "../../reducers/content.reducer";
import { getContent } from "../../hooks/useGetContent";
import { useMediaQuery } from "react-responsive";
import { mobileDeviceResolution } from "../../utils/utils";
import { useGetQuizzes } from "../../hooks/useGetQuizzes";
import { QuizContext } from "../../reducers/quiz.reducer";
import * as QuizActionTypes from "../../reducers/quiz.actionTypes";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      display: "flex",
    },
    popupContentRoot: {
      height: "100%",
    },
    popupContent: {
      padding: "24px",
      backgroundColor: "#131a42",
      color: "#FFF",
      display: "flex",
      flexDirection: "column",
    },
    language: {
      cursor: "pointer",
      textDecoration: "underline",
      marginBottom: "5px",
      color: "#757B96",
      marginLeft: "10px",
    },
    selectedLanguage: {
      cursor: "pointer",
      color: "#F8C31D",
      marginBottom: "5px",
      marginLeft: "10px",
    },
    changeRegion: {
      cursor: "pointer",
      textDecoration: "underline",
      marginBottom: "20px",
      color: "#757B96",
      marginLeft: "45px",
    },
  })
);

export function LanguageSelection(props: any) {
  const classes = useStyles();

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const globalRanking = t("GlobalRanking");
  const changeRegion = t("ChangeRegion");
  const backToQuiz = t("BackToQuiz");

  const { state, dispatch } = useContext(RegionContext);
  const quizContext = useContext(QuizContext);
  const [region, setRegion] = useState(new RegionModel());
  const [languages, setLanguages] = useState(Array<LanguageModel>());
  const [selectedLanguage, setSelectedLanguage] = useState(new LanguageModel());
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    props.selectedChapterElement
  );
  const languageContext = useContext(LanguageContext);
  const contentContext = useContext(ContentContext);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  useEffect(() => {
    setRegion(state.selectedRegion);

  }, [state.selectedRegion]);
  useEffect(() => {
    if (languageContext.state.selectedLanguage) {
      setSelectedLanguage(languageContext.state.selectedLanguage);
      GetQuizzes();
    }
  }, [languageContext.state.selectedLanguage]);
  useEffect(() => {
    setLanguages(languageContext.state.availableLanguages);
  }, [languageContext.state.availableLanguages]);


  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopup = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  function GetQuizzes() {
    useGetQuizzes(
      quizContext.dispatch,
      languageContext.state.selectedLanguage.id
    );
  }

  function onSelectLanguage(language: LanguageModel) {
    languageContext.dispatch?.({
      type: LanguageActionTypes.SelectLanguage,
      payload: language,
    });
  }

  function isSelectedLanguage(language: LanguageModel) {
    if (language && selectedLanguage && language.id === selectedLanguage.id) {
      return classes.selectedLanguage;
    } else {
      return classes.language;
    }
  }

  function onChangeRegionClick() {
    let path = "region-selection";
    history.push(path);
  }

  function onGlobalRankingClick() {
    let path = "ranking";
    history.push(path);
  }

  function onBackToQuizClick() {
    let path = "campaign";
    history.push(path);
    quizContext.dispatch?.({
      type: QuizActionTypes.SetCurrentQuizPage,
      payload: null,
    });
  }

  return (
    <div className="language-selection-root-container fade-in-container">
      {!isMobileDevice ? (
        <div className="desktop-or-laptop-device">
          {!props.isOnGlobalRanking ? (
            <div className="background-container">
              <div className="label-container" onClick={onGlobalRankingClick}>
                <img className="earth" src={earth} alt="earth" />
                <div className="label">{globalRanking}</div>
              </div>
            </div>
          ) : (
            <div className="background-container">
              <div className="label-container" onClick={onBackToQuizClick}>
                <img
                  className="questionMark"
                  src={questionMark}
                  alt="questionMark"
                />
                <div className="label">{backToQuiz}</div>
              </div>
            </div>
          )}
          <div className="language-selection-container">
            {region ? (
              <RegionAndFlag region={region} shouldShowAbbreviation={true} />
            ) : null}
            <img
              className="arrow"
              src={arrow}
              alt="arrow"
              onClick={openPopup}
            />
          </div>
        </div>
      ) : (
        <div className="mobile-device">
          {region ? (
            <img className="flag" src={region.icon} alt={region.name} />
          ) : null}
          <img className="arrow" src={arrow} alt="arrow" onClick={openPopup} />
        </div>
      )}
      <div className={classes.popupContentRoot}>
        <Popover
          classes={{ paper: "customLanguageSelectionPopupPaper" }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={classes.popupContent}>
            <div>
              <RegionAndFlag region={region} />
              <div
                className={classes.changeRegion}
                onClick={onChangeRegionClick}
              >
                {changeRegion}
              </div>
            </div>
            <div className="language-selection">
              <div className="image-container">
                <img src={languageSelection} alt="language-selection" />
              </div>
              <div className="languages-container">
                {languages.length > 0
                  ? languages.map((language) => (
                    <div
                      className={isSelectedLanguage(language)}
                      key={language.id}
                      onClick={() => onSelectLanguage(language)}
                    >
                      {language.name}
                    </div>
                  ))
                  : null}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
}
