import { AnswerModel } from "../../models";
import "./WrongAnswer.component.scss";
import Fab from "@material-ui/core/Fab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMediaQuery } from "react-responsive";
import wrongAnswer from "../../assets/icons/wrong-answer.svg";
import correctAnswer from "../../assets/icons/correct-answer.svg";
import strikethrough from "../../assets/icons/strikethrough.svg";
import { Typography } from "@material-ui/core";
import { mobileDeviceResolution } from "../../utils/utils";
import { useTranslation } from "react-i18next";
interface WrongAnswerProps {
  answers: AnswerModel[];
  correctAnswers: AnswerModel[];
  onContinueClick: any;
}

export function WrongAnswer(props: WrongAnswerProps) {
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const { t, i18n } = useTranslation();

  return (
    <div className="wrong-answer-root fade-in-container">
      <div className="center-container">
        <div className="details-container">
          <div className="wrong-answer-icon">
            <img src={wrongAnswer} alt="wrong-answer-icon" />
          </div>
          <div
            className={
              isMobileDevice ? "info-text mobile-version" : "info-text"
            }
          >
            {t("TryAgainOnNextQuestion")}
          </div>
          {
            !isMobileDevice ? (
              <div className="wrong-answer-wrapper">
              <div
                className={
                  isMobileDevice ? "wrong-answer mobile-device" : "wrong-answer"
                }
              >
                <Typography variant="h3">
                  {props.answers.map((answer) => (
                    <div key={answer.id}>{answer.answer}</div>
                  ))}
                </Typography>
                <img
                  className={
                    isMobileDevice ? "strikethrough mobile-device" : "strikethrough"
                  }
                  src={strikethrough}
                  alt="strikethrough"
                />
              </div>
            </div>
            ):
            (
              <div
              className={
                isMobileDevice ? "wrong-answer mobile-device" : "wrong-answer"
              }
            >
              <Typography variant="h3">
                {props.answers.map((answer) => (
                  <div key={answer.id}>{answer.answer}</div>
                ))}
              </Typography>
              <img
                className={
                  isMobileDevice ? "strikethrough mobile-device" : "strikethrough"
                }
                src={strikethrough}
                alt="strikethrough"
              />
            </div>
            )
          }


          {!isMobileDevice ? (
            <div className="correct-answer-container">
              <img
                className="correct-answer-icon"
                src={correctAnswer}
                alt="correct-answer-icon"
              />
              <div className="correct-answer">
                {props.correctAnswers.map((answer, index) => (
                  <div key={answer.id}>
                    {answer.answer +
                      (index < props.correctAnswers.length - 1 ? ", " : "")}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        {
          isMobileDevice ? (
            <div className="correct-answer-container-mobile-version">
              <div className="translated-container"></div>
              <img
                className="correct-answer-icon"
                src={correctAnswer}
                alt="correct-answer-icon"
              />
              <div className="correct-answer">
                {props.correctAnswers.map((answer, index) => (
                  <div key={answer.id}>
                    {answer.answer +
                      (index < props.correctAnswers.length - 1 ? ", " : "")}
                  </div>
                ))}
              </div>
            </div>
          ) : null
        }
        {!isMobileDevice ? (
          <div className="continue-button-container">
            <Fab
              color="inherit"
              aria-label="add"
              onClick={props.onContinueClick}
            >
              <ExpandMoreIcon />
            </Fab>
            <div className="label">{t("Continue")} </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
