import { Fab, Typography } from "@material-ui/core";
import "./DetailedReport.component.scss";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { QuizContext } from "../../reducers/quiz.reducer";
import { useContext, useEffect, useState } from "react";
import * as QuizActionTypes from "../../reducers/quiz.actionTypes";
import { mobileDeviceResolution, pages } from "../../utils/utils";
import email from "../../assets/icons/email.svg";
import { QuestionReportModel } from "../../models";
import check from "../../assets/icons/check-white.svg";
import wrongAnswer from "../../assets/icons/wrong-answer.svg";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

interface DetailedReportProps {
  questionsReport: Array<QuestionReportModel>;
}

var validationMessage: string;

export function DetailedReport(props: DetailedReportProps) {
  const quizContext = useContext(QuizContext);
  const classes = useStyles();
  const [emailInput, setEmailInput] = useState(String);
  const [disableSubmitButton, setDisabledSubmitButton] = useState(Boolean);
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (validationMessage === "" && emailInput !== "") {
      setDisabledSubmitButton(false);
    } else {
      setDisabledSubmitButton(true);
    }
  }, [emailInput]);

  function onClickBack() {
    quizContext.dispatch?.({
      type: QuizActionTypes.SetCurrentQuizPage,
      payload: pages.submitPage,
    });
  }

  /*Based on the feedback submit email functionality will be removed for the current version of the application */
  // function onSubmitClick() {
  //   console.log(emailInput);
  // }

  // const handleEmailChange = (event: any) => {
  //   validationMessage = event.target.validationMessage;

  //   setEmailInput(event.target.value);
  // };

  return (
    <div className="detailed-report-root-container fade-in-container">
      <div
        className={
          !isMobileDevice
            ? "questions-container"
            : "questions-container mobile-version"
        }
      >
        <Typography
          className={isMobileDevice ? "title mobile-version" : "title"}
          variant="h2"
        >
          {t("YourReport")}
        </Typography>
        {!isMobileDevice ? (
          <div className="back-button-container">
            <div className="label">{t("Back")}</div>
            <Fab color="inherit" aria-label="add" onClick={onClickBack}>
              <ExpandLessIcon />
            </Fab>
          </div>
        ) : null}
        {!isMobileDevice ? (
          <div className="header-container">
            <div className="question-result-container">
              <div className="header-label result">{t("Result")}</div>
              <div className="header-label question">{t("Question")}</div>
            </div>
            <div className="header-label answer">{t("CorrectAnswer")}</div>
          </div>
        ) : null}
        {props.questionsReport.map((questionReport, index) => (
          <div
            className={
              !isMobileDevice
                ? "question-report-container"
                : "question-report-container mobile-version"
            }
          >
            <div
              className={
                isMobileDevice
                  ? "question-report-content mobile-version"
                  : "question-report-content"
              }
            >
              <div
                className={
                  isMobileDevice
                    ? "result-and-question-container mobile-version"
                    : "result-and-question-container"
                }
              >
                <div
                  className={
                    isMobileDevice
                      ? "result-container mobile-version"
                      : "result-container"
                  }
                >
                  <div
                    className={
                      isMobileDevice
                        ? "question-number mobile-version"
                        : "question-number"
                    }
                  >
                    {questionReport.id}
                  </div>
                  <div
                    className={
                      questionReport.isCorrect
                        ? isMobileDevice
                          ? "result correct-result mobile-version"
                          : "result correct-result"
                        : isMobileDevice
                        ? "result wrong-result mobile-version"
                        : "result wrong-result"
                    }
                  >
                    {questionReport.isCorrect ? (
                      <img src={check} alt="correctAnswer"></img>
                    ) : (
                      <img src={wrongAnswer} alt="wrongAnswer"></img>
                    )}
                  </div>
                </div>
                <div
                  className={
                    isMobileDevice ? "question mobile-version" : "question"
                  }
                >
                  {questionReport.question}
                </div>
              </div>
              <div
                className={
                  isMobileDevice
                    ? "correct-answer mobile-version"
                    : "correct-answer"
                }
              >
                {questionReport.correctAnswer.map((answer, index) => (
                  <div key={answer.id}>
                    {answer.answer +
                      (index < questionReport.correctAnswer.length - 1
                        ? ", "
                        : "")}
                  </div>
                ))}
              </div>
            </div>
            {props.questionsReport.length !== index + 1 && !isMobileDevice ? (
              <div className="line"></div>
            ) : null}
          </div>
        ))}
      </div>
      {/*Based on the feedback submit email functionality will be removed for the current version of the application */}
      {/* <div
        className={
          !isMobileDevice ? "email-container" : "email-container mobile-version"
        }
      >
        <div
          className={!isMobileDevice ? "info-text" : "info-text mobile-version"}
        >
          {t("SubmitEmailText")}
        </div>
        <div
          className={
            !isMobileDevice
              ? "submit-container"
              : "submit-container mobile-version"
          }
        >
          <div className="email-address-container">
            <div
              className={
                !isMobileDevice
                  ? "input-container"
                  : "input-container mobile-version"
              }
            >
              <img className="email" src={email} alt="email"></img>
              <TextField
                className="input"
                id="standard-basic"
                placeholder={t("YourEmail")}
                InputProps={{ classes }}
                fullWidth={true}
                onChange={handleEmailChange}
                type="email"
              />
            </div>
            <div className="line"></div>
          </div>
          <div
            className={
              !isMobileDevice
                ? "submit-button-container"
                : "submit-button-container mobile-version"
            }
          >
            <button
              disabled={disableSubmitButton}
              className={
                !disableSubmitButton
                  ? "submit-button"
                  : "submit-button disabled"
              }
              onClick={onSubmitClick}
            >
              {t("Submit")}
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
