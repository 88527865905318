import { OfficerWild } from "../OfficerWild/OfficerWild.component";
import "./ChapterEnd.component.scss";
import Fab from "@material-ui/core/Fab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import info from "../../assets/icons/info.svg";
import skip from "../../assets/icons/skip.svg";
import { useContext } from "react";
import { ContentContext } from "../../reducers/content.reducer";
import * as ContentActionTypes from "../../reducers/content.actionTypes";
import snake from "../../assets/snake.svg";
import fingerprint from "../../assets/fingerprint.png";
import europe from "../../assets/europe.svg";
import {
  mobileDeviceResolution,
  tabletDeviceResolution,
} from "../../utils/utils";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
interface ChapterEndProps {
  chapterNumber: number;
  onContinueClick: any;
}
export function ChapterEnd(props: ChapterEndProps) {
  const contentContext = useContext(ContentContext);
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const isTabletDevice = useMediaQuery({ query: tabletDeviceResolution });
  const { t, i18n } = useTranslation();

  function onReadMoreClick() {
    contentContext.dispatch?.({
      type: ContentActionTypes.SetOpenContentNumber,
      payload: props.chapterNumber,
    });
  }

  function getEndChapterText() {
    switch (props.chapterNumber) {
      case 1:
        return t("ChapterOneEndText");
      case 2:
        return t("ChapterTwoEndText");
      case 3:
        return t("ChapterThreeEndText");
    }
  }

  function getChapterImage() {
    switch (props.chapterNumber) {
      case 1:
        return { image: snake, name: "snake" };
      case 2:
        return { image: fingerprint, name: "fingerprint" };
      case 3:
        return { image: europe, name: "europe" };
    }
  }

  return (
    <div className="chapter-end-root-container fade-in-container">
      <div className="skip-button-container">
        <div className="label">
          {t("ChapterEndLabel", { chapterNumber: props.chapterNumber })}
        </div>
        <Fab
          className="skip-button"
          disableRipple={true}
          color="inherit"
          aria-label="add"
        >
          <img className="skip" src={skip} alt="skip" />
        </Fab>
      </div>
      <div
        className={
          isMobileDevice
            ? "officer-wild mobile-version"
            : isTabletDevice
            ? "officer-wild tablet-device"
            : "officer-wild"
        }
      >
        <OfficerWild
          displayOfficerUnderContent={isMobileDevice ? true : false}
          hasReadMoreButton={isMobileDevice ? true : false}
          onReadMoreClick={onReadMoreClick}
          image={getChapterImage()}
          largeSize={true}
          title={t("DidYouKnow")}
          content={getEndChapterText()}
        />
      </div>
      {!isMobileDevice ? (
        <div className="buttons-container">
          <div className="read-more-button-container">
            <Fab color="inherit" aria-label="add" onClick={onReadMoreClick}>
              <img className="info" src={info} alt="info" />
            </Fab>
            <div className="read-more-label">{t("ReadMoreDesktop")}</div>
          </div>
          <div className="continue-button-container">
            <Fab
              color="inherit"
              aria-label="add"
              onClick={props.onContinueClick}
            >
              <ExpandMoreIcon />
            </Fab>
            <div className="label">{t("ContinueQuiz")}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
