import "./App.scss";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { theme } from "./theme";
import { RegionSelectionPage } from "./pages/RegionSelection/RegionSelection.page";
import { CampaignPage } from "./pages/Campaign/Campaign.page";
import { RankingPage } from "./pages/RankingPage/Ranking.page";
import { RegionProvider } from "./reducers/region.reducer";
import { ContentProvider } from "./reducers/content.reducer";

import { QuizProvider } from "./reducers/quiz.reducer";
import { LanguageProvider } from "./reducers/language.reducer";
import { useEffect } from 'react';

function App() {
  useEffect(() => {

    // Preload heavy images to prevent loading glitches.
    // Be aware of how many assets are loaded here, to not overload the network.
    preloadCagesImages();
    preloadBackgroundImages();
    preloadRankingImages();
    preloadImages();
  }, []);

  function preloadCagesImages() {
    const images = importAll(require.context('./assets/cages', false, /\.(png|jpe?g|svg)$/));
    Object.keys(images).forEach((item) => {
      const img = new Image().src = images[item].default;
    })
  }
  function preloadRankingImages() {
    const images = importAll(require.context('./assets/ranking', false, /\.(png|jpe?g|svg)$/));
    Object.keys(images).forEach((item) => {
      const img = new Image().src = images[item].default;
    })
  }
  function preloadBackgroundImages() {
    const images = importAll(require.context('./assets/backgrounds', false, /\.(png|jpe?g|svg)$/));
    Object.keys(images).forEach((item) => {
      const img = new Image().src = images[item].default;
    })
  }
  function preloadImages() {
    const images = importAll(require.context('./assets/', false, /\.(png|jpe?g|svg)$/));
    Object.keys(images).forEach((item) => {
      const img = new Image().src = images[item].default;
    })
  }

  function importAll(r: any) {
    let images: any = {};
    r.keys().map((item: any, index: any) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="root-container">
          <RegionProvider>
            <ContentProvider>
              <QuizProvider>
                <LanguageProvider>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/region-selection" />
                    </Route>
                    <Route path="/region-selection">
                      <RegionSelectionPage />
                    </Route>
                    <Route path="/campaign">
                      <CampaignPage />
                    </Route>
                    <Route path="/ranking">
                      <RankingPage />
                    </Route>
                  </Switch>
                </LanguageProvider>
              </QuizProvider>
            </ContentProvider>
          </RegionProvider>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
