export class LanguageModel {
  id!: number;
  name!: string;
  icon!: string;
  code!: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
