import React, { useContext, useEffect, useState } from "react";
import { Question } from "../../components/Question/Question.component";
import { QuizContext } from "../../reducers/quiz.reducer";
import "./Quiz.page.scss";
import { AnswerModel, QuestionModel, QuestionReportModel } from "../../models";
import { WrongAnswer } from "../../components/WrongAnswer/WrongAnswer.component";
import { CorrectAnswer } from "../../components/CorrectAnswer/CorrectAnswer.component";
import { useMediaQuery } from "react-responsive";
import { mobileDeviceResolution, pages } from "../../utils/utils";
import * as QuizActionTypes from "../../reducers/quiz.actionTypes";
import { QuizScore } from "../../components/QuizScore/QuizScore.component";
import { LanguageContext } from "../../reducers/language.reducer";
import { ChapterEnd } from "../../components/ChapterEnd/ChapterEnd.component";
import { RegionContext } from "../../reducers/region.reducer";
import { useGetInitialQuizScore } from "../../hooks/usetGetInitialQuizScore";
import apiService from "../../services/api.service";
import { useGetFinalQuizScore } from "../../hooks/useGetFinalQuizScore";
import { useGetGlobalQuizScore } from "../../hooks/useGetGlobalQuizScore";
import { QuizScoreModel } from "../../models/quiz-score.model";
import { useTranslation } from "react-i18next";
import { DetailedReport } from "../../components/DetailedReport/DetailedReport.component";
import { QuizDescription } from "../../components/QuizDescription/QuizDescription.component";

var currentQuestionNumber: number;
var showNextQuestionOnMobile: boolean;
var answersForSubmitting: any[] = [];

export function QuizPage() {
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const quizContext = useContext(QuizContext);
  const regionContext = useContext(RegionContext);
  const languageContext = useContext(LanguageContext);
  const [currentQuestion, setCurrentQuestion] = useState(new QuestionModel());
  const [questions, setQuestions] = useState(new Array<QuestionModel>());
  const [questionsReport, setQuestionsReport] = useState(
    new Array<QuestionReportModel>()
  );
  const [showSubmitButton, setShowSubmitButton] = useState(Boolean);
  const [selectedAnswers, setSelectedAnswers] = useState(Array<AnswerModel>());
  const [shouldDisplayResult, setShouldDisplayResult] = useState(Boolean);
  const [shouldDisplayChapterEnd, setShouldDisplayChapterEnd] =
    useState(Boolean);
  const [shouldDisplayQuizDescription, setShouldDisplayQuizDescription] =
    useState(Boolean);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(Boolean);
  const [showQuizScore, setShowQuizScore] = useState(Boolean);
  const [showDetailedReport, setShowDetailedReport] = useState(Boolean);
  const [initialQuizScore, setInitialQuizScore] = useState(
    new QuizScoreModel()
  );
  const [finalQuizScore, setFinalQuizScore] = useState(new QuizScoreModel());
  const [globalQuizScore, setGlobalQuizScore] = useState(new QuizScoreModel());
  const [chapterEndNumber, setChapterEndNumber] = useState(1);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GetInitialQuizScore();
    currentQuestionNumber = 0;
    showNextQuestionOnMobile = false;
    answersForSubmitting = [];
  }, []);

  useEffect(() => {
    if (
      quizContext.state.quizzes &&
      quizContext.state.quizzes.length > 0 &&
      quizContext.state.quizzes[0].questions?.length > 0
    ) {
      setQuestions(quizContext.state.quizzes[0].questions);
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCurrentChapterNumber,
        payload: quizContext.state.quizzes[0].questions[0].chapter,
      });
    }
  }, [quizContext.state.quizzes]);

  useEffect(() => {
    if (quizContext.state.currentChapterNumber) {
      setChapterEndNumber(quizContext.state.currentChapterNumber);
    }
  }, [quizContext.state.currentChapterNumber]);

  useEffect(() => {
    if (
      quizContext.state.currentQuizPage === pages.resultPage &&
      selectedAnswers.length > 0
    ) {
      displayResultsOnMobileVersion();
    }
    if (
      quizContext.state.currentQuizPage === pages.questionPage &&
      showNextQuestionOnMobile
    ) {
      onShowNextQuestionClick();
    }
    quizContext.state.currentQuizPage === pages.quizInfoPage
      ? setShouldDisplayQuizDescription(true)
      : setShouldDisplayQuizDescription(false);

    if (quizContext.state.currentQuizPage === pages.chapterEndPage) {
      setShouldDisplayChapterEnd(true);
      quizContext.dispatch?.({
        type: QuizActionTypes.SetIsEndOfChapter,
        payload: false,
      });
    }
    if (quizContext.state.currentQuizPage === pages.detailedReportPage) {
      setShowDetailedReport(true);
    }
    if (quizContext.state.currentQuizPage === pages.submitPage) {
      setShowDetailedReport(false);
    }
    if (quizContext.state.currentQuizPage !== pages.quizInfoPage) {
      showNextQuestionOnMobile = true;
    }
  }, [quizContext.state.currentQuizPage]);

  useEffect(() => {
    if (questions && questions.length > 0) {
      setCurrentQuestion(questions[0]);
    }
  }, [questions]);

  useEffect(() => {
    if (quizContext.state.initialScore) {
      setInitialQuizScore(quizContext.state.initialScore);
    }
  }, [quizContext.state.initialScore]);

  useEffect(() => {
    if (quizContext.state.finalScore) {
      setFinalQuizScore(quizContext.state.finalScore);
    }
  }, [quizContext.state.finalScore]);

  useEffect(() => {
    if (quizContext.state.globalScore) {
      setGlobalQuizScore(quizContext.state.globalScore);
    }
  }, [quizContext.state.globalScore]);

  function GetInitialQuizScore() {
    useGetInitialQuizScore(
      quizContext.dispatch,
      regionContext.state.selectedRegion.id
    );
  }
  function GetFinalQuizScore() {
    useGetFinalQuizScore(
      quizContext.dispatch,
      regionContext.state.selectedRegion.id
    );
  }
  function GetGlobalQuizScore() {
    useGetGlobalQuizScore(quizContext.dispatch);
  }

  function onDisplayResultClick(selectedAnswers: AnswerModel[]) {
    if (selectedAnswers.length > 0) {
      quizContext.dispatch?.({
        type: QuizActionTypes.SetIsSelectedAnswer,
        payload: true,
      });
      setSelectedAnswers(selectedAnswers);
      if (!isMobileDevice) {
        setShouldDisplayResult(true);
        let correctAnswers = getCorrectAnswers();
        let isCorrectAnswer;
        if (selectedAnswers.length === correctAnswers.length) {
          selectedAnswers.forEach((answer) => {
            if (answer.isDesired) {
              setIsCorrectAnswer(true);
              isCorrectAnswer = true;
            } else {
              isCorrectAnswer = false;
              setIsCorrectAnswer(false);
              return;
            }
          });
        } else {
          isCorrectAnswer = false;
          setIsCorrectAnswer(false);
        }
        setQuestionsReport([
          ...questionsReport,
          new QuestionReportModel({
            question: currentQuestion.question,
            isCorrect: isCorrectAnswer,
            correctAnswer: correctAnswers,
            id:
              questions.findIndex(
                (question) => question.id === currentQuestion.id
              ) + 1,
          }),
        ]);
        if (isCorrectAnswer) {
          quizContext.dispatch?.({
            type: QuizActionTypes.SetCorrectAnswers,
          });
        }
        if (currentQuestionNumber + 1 === questions.length) {
          selectedAnswers.forEach((answer) => {
            answersForSubmitting.push({
              questionId: currentQuestion.id,
              answerId: answer.id,
            });
          });
          apiService.submitQuiz(
            answersForSubmitting,
            regionContext.state.selectedRegion.id,
            quizContext.state.quizzes[0].id
          );
        }
      }
    } else {
      quizContext.dispatch?.({
        type: QuizActionTypes.SetIsSelectedAnswer,
        payload: false,
      });
    }
  }

  function displayResultsOnMobileVersion() {
    setShouldDisplayResult(true);
    let correctAnswers = getCorrectAnswers();
    let isCorrectAnswer;
    if (selectedAnswers.length === correctAnswers.length) {
      selectedAnswers.forEach((answer) => {
        if (answer.isDesired) {
          quizContext.dispatch?.({
            type: QuizActionTypes.SetCurrentQuizPage,
            payload: pages.correctAnswer,
          });
          isCorrectAnswer = true;
          setIsCorrectAnswer(true);
        } else {
          quizContext.dispatch?.({
            type: QuizActionTypes.SetCurrentQuizPage,
            payload: pages.wrongAnswer,
          });
          isCorrectAnswer = false;
          setIsCorrectAnswer(false);
          return;
        }
      });
    } else {
      isCorrectAnswer = false;
      setIsCorrectAnswer(false);
    }
    setQuestionsReport([
      ...questionsReport,
      new QuestionReportModel({
        question: currentQuestion.question,
        isCorrect: isCorrectAnswer,
        correctAnswer: correctAnswers,
        id:
          questions.findIndex(
            (question) => question.id === currentQuestion.id
          ) + 1,
      }),
    ]);
    if (isCorrectAnswer) {
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCorrectAnswers,
      });
    }
    if (currentQuestionNumber + 1 === questions.length) {
      selectedAnswers.forEach((answer) => {
        answersForSubmitting.push({
          questionId: currentQuestion.id,
          answerId: answer.id,
        });
      });
      apiService.submitQuiz(
        answersForSubmitting,
        regionContext.state.selectedRegion.id,
        quizContext.state.quizzes[0].id
      );
    }
    if (currentQuestionNumber + 1 < questions.length) {
      if (
        currentQuestion.chapter < questions[currentQuestionNumber + 1].chapter
      ) {
        quizContext.dispatch?.({
          type: QuizActionTypes.SetIsEndOfChapter,
          payload: true,
        });
      } else {
        quizContext.dispatch?.({
          type: QuizActionTypes.SetIsEndOfChapter,
          payload: false,
        });
      }
    }
  }

  function getCorrectAnswers() {
    let correctAnswers: AnswerModel[] = [];
    currentQuestion.answers.forEach((answer) => {
      if (answer.isDesired) {
        correctAnswers.push(answer);
      }
    });
    return correctAnswers;
  }

  function onShowNextQuestionClick() {
    setShouldDisplayResult(false);
    if (!isMobileDevice) {
      onDisplayChapterEnd();
    } else {
      setChapterEndNumber(currentQuestion.chapter);
      setShouldDisplayChapterEnd(false);
    }
    selectedAnswers.forEach((answer) => {
      answersForSubmitting.push({
        questionId: currentQuestion.id,
        answerId: answer.id,
      });
    });
    currentQuestionNumber++;
    if (currentQuestionNumber < questions.length) {
      setCurrentQuestion(questions[currentQuestionNumber]);
    }
    if (isMobileDevice && questions[currentQuestionNumber]) {
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCurrentChapterNumber,
        payload: questions[currentQuestionNumber].chapter,
      });
    }
    if (currentQuestionNumber === questions.length) {
      setShowSubmitButton(true);
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCurrentQuizPage,
        payload: pages.submitPage,
      });
      GetFinalQuizScore();
      GetGlobalQuizScore();
      setShowQuizScore(true);
      quizContext.dispatch?.({
        type: QuizActionTypes.SetEndTime,
        payload: new Date(),
      });
    }
    setSelectedAnswers([]);
  }

  function onDisplayChapterEnd() {
    if (currentQuestionNumber + 1 < questions.length) {
      if (
        currentQuestion.chapter < questions[currentQuestionNumber + 1].chapter
      ) {
        setShouldDisplayChapterEnd(true);
      } else {
        setShouldDisplayChapterEnd(false);
      }
    }
  }

  function showNextChapterQuestions() {
    setShouldDisplayChapterEnd(false);
    quizContext.dispatch?.({
      type: QuizActionTypes.SetCurrentChapterNumber,
      payload: questions[currentQuestionNumber + 1]
        ? questions[currentQuestionNumber + 1].chapter
        : 5,
    });
  }

  function getQuestionsReport() {
    return `${quizContext.state.correctAnswers}/${questions.length}`;
  }

  function getRegionReport() {
    return `+${finalQuizScore.contribution}%`
  }

  function getQuizNote() {
    if (quizContext.state.correctAnswers <= 9) {
      return t("TryAgain");
    } else if (quizContext.state.correctAnswers <= 12) {
      return t("Poor");
    } else if (quizContext.state.correctAnswers <= 17) {
      return t("Great");
    } else {
      return t("Perfect");
    }
  }

  return (
    <div className="quiz-root-container">
      {showQuizScore && !shouldDisplayChapterEnd ? (
        showDetailedReport ? (
          <DetailedReport questionsReport={questionsReport} />
        ) : (
          <QuizScore
            selectedRegion={regionContext.state.selectedRegion}
            questionsReport={getQuestionsReport()}
            regionReport={getRegionReport()}
            globalReport={`${globalQuizScore.correctPct}%`}
            globalCount={globalQuizScore.count}
            quizNote={getQuizNote()}
          ></QuizScore>
        )
      ) : !shouldDisplayResult && !shouldDisplayChapterEnd ? (
        !shouldDisplayQuizDescription ? (
          <Question
            question={currentQuestion}
            showSubmitButton={showSubmitButton}
            totalQuestions={questions.length}
            doneQuestions={currentQuestionNumber}
            onShowResultClick={onDisplayResultClick}
          />
        ) : (
          <QuizDescription />
        )
      ) : (
        <div className="result-container">
          {shouldDisplayChapterEnd ? (
            <div className="chapter-end-container">
              <ChapterEnd
                chapterNumber={chapterEndNumber}
                onContinueClick={showNextChapterQuestions}
              />
            </div>
          ) : (
            <div className="answer-container">
              {isCorrectAnswer ? (
                <div className="correct-answer-container">
                  <CorrectAnswer
                    onContinueClick={onShowNextQuestionClick}
                    answers={selectedAnswers}
                  ></CorrectAnswer>
                </div>
              ) : (
                <div className="wrong-answer-container">
                  <WrongAnswer
                    onContinueClick={onShowNextQuestionClick}
                    answers={selectedAnswers}
                    correctAnswers={getCorrectAnswers()}
                  ></WrongAnswer>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
