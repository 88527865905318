import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LearnMenu } from "../../components/LearnMenu/LearnMenu.component";
import { useMediaQuery } from "react-responsive";
import { MobileMenu } from "../../components/MobileMenu/MobileMenu.component";
import { LanguageSelection } from "../../components/LanguageSelection/LanguageSelection.component";
import { Title } from "../../components/Title/Title.component";
import "./Campaign.page.scss";
import { RegionContext } from "../../reducers/region.reducer";
import { useHistory } from "react-router";
import Fab from "@material-ui/core/Fab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { QuizPage } from "../Quiz/Quiz.page";
import { QuizContext } from "../../reducers/quiz.reducer";
import { mobileDeviceResolution, pages } from "../../utils/utils";
import * as QuizActionTypes from "../../reducers/quiz.actionTypes";
import { OfficerWild } from "../../components/OfficerWild/OfficerWild.component";
import EucpnLogoWhite from "../../assets/icons/eucpn-logo-white.svg";
import EucpnLogo from "../../assets/icons/eucpn-logo.svg";
import EuLogo from "../../assets/icons/eu-flag2.svg";
import * as RegionActionTypes from "../../reducers/region.actionTypes";
import { LanguageContext } from '../../reducers/language.reducer';
import * as LanguageActionTypes from "../../reducers/language.actionTypes";
import { useGetLanguages } from '../../hooks/useGetLanguages';
import { getContent } from '../../hooks/useGetContent';
import { ContentContext } from '../../reducers/content.reducer';

export function CampaignPage() {
  const quizContext = useContext(QuizContext);
  const { state, dispatch } = useContext(RegionContext);
  const history = useHistory();
  const [isDetailedReportDisplayed, setIsDetailedReportDisplayed] =
    useState(Boolean);
  const [quizPage, setQuizPage] = useState(String);
  const [applyAnimation, setApplyAnimation] = useState(false);

  const { t, i18n } = useTranslation();
  const titleProps = {
    multipleTitles: [t("IntroTitle1"), t("IntroTitle2"), t("IntroTitle3")],
    dark: true,
  };
  const [menuElement, setMenuElement] =
    React.useState<HTMLButtonElement | null>(null);

  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const [showQuiz, setShowQuiz] = useState(Boolean);
  const languageContext = useContext(LanguageContext);
  const contentContext = useContext(ContentContext);

  useEffect(() => {
    if (!state.selectedRegion) {
      history.push("/");
    }
  }, [state]);

  useEffect(() => {
    if (
      quizContext.state.currentQuizPage === pages.questionPage ||
      quizContext.state.currentQuizPage === pages.quizInfoPage
    ) {
      setShowQuiz(true);
    }
    setIsDetailedReportDisplayed(
      quizContext.state.currentQuizPage === pages.detailedReportPage
    );
    setQuizPage(quizContext.state.currentQuizPage);
  }, [quizContext.state]);

  useEffect(() => {
    setTimeout(() => {
      dispatch?.({
        type: RegionActionTypes.SetAnimationFlag,
        payload: true,
      });
    }, 2200);
  }, []);

  useEffect(() => {
    setApplyAnimation(state.animationIsNeeded);
  }, [state.animationIsNeeded]);


  useEffect(() => {
    if (languageContext.state.selectedLanguage) {
      if(languageContext.state.selectedLanguage.code) {
        i18n.changeLanguage(languageContext.state.selectedLanguage.code);
      }
    }
  }, [languageContext.state.selectedLanguage]);

  useEffect(() => {
    if(!languageContext.state.selectedLanguage) {
      if(languageContext.state.availableLanguages[0]) {
      languageContext.dispatch?.({
        type: LanguageActionTypes.SelectLanguage,
        payload: languageContext.state.availableLanguages[0],
      });
      i18n.changeLanguage(languageContext.state.availableLanguages[0].code);
    }

    }
  }, [languageContext.state.availableLanguages]);

  useEffect(() => {
    if (state.selectedRegion && state.selectedRegion.id) {
      GetLanguages();
    }
  }, [state.selectedRegion]);

  function GetLanguages() {
    useGetLanguages(languageContext.dispatch, state.selectedRegion.id);
  }

    useEffect(() => {
      if (languageContext.state.selectedLanguage && languageContext.state.selectedLanguage.id && state.selectedRegion && state.selectedRegion.id) {
        getContent(contentContext.dispatch, state.selectedRegion.id, languageContext.state.selectedLanguage.id);
      }
    }, [languageContext.state.selectedLanguage]);

  function onLearnMoreSelected(event: any) {
    setMenuElement(event.currentTarget);
  }

  function onSectionClose() {
    setMenuElement(null);
  }

  function onTakeQuizClick() {
    if (quizContext.state.currentQuizPage !== pages.quizInfoPage) {
      quizContext.dispatch?.({
        type: QuizActionTypes.ClearCorrectAnswers,
      });
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCurrentQuizPage,
        payload: pages.quizInfoPage,
      });
    } else {
      quizContext.dispatch?.({
        type: QuizActionTypes.SetCurrentQuizPage,
        payload: pages.questionPage,
      });
      quizContext.dispatch?.({
        type: QuizActionTypes.SetStartTime,
        payload: new Date(),
      });
    }
  }

  return (
    <div
      className={
        isMobileDevice
          ? "campaign-page-root-container mobile-version fade-in-container"
          : isDetailedReportDisplayed
          ? "campaign-page-root-container detailed-report fade-in-container"
          : "campaign-page-root-container desktop-version fade-in-container"
      }
    >
      <LearnMenu
        selectedElement={menuElement}
        showStartQuizButton={applyAnimation}
        onClose={onSectionClose}
        officerWildTakeQuizClick={onTakeQuizClick}
      />
      <div
        className={`${applyAnimation ? "dark-background" : ""} ${
          isMobileDevice
            ? "intro-page-root-container"
            : "intro-page-root-container desktop-layout"
        }`}
      >
        {!showQuiz ? (
          <div className="center-container">
            <div className="top-container">
              <Title
                multipleLinesTitle={titleProps.multipleTitles}
                dark={applyAnimation}
                largeSize={isMobileDevice ? false : true}
                smallSize={isMobileDevice ? true : false}
                centered={isMobileDevice ? true : false}
                transformed={true}
                hasAnimation={!applyAnimation}
              />
            </div>
            <div
              className={`${!applyAnimation ? "logo-reveal" : ""} ${
                isMobileDevice
                  ? "bottom-container bottom-container-mobile"
                  : "bottom-container"
              }`}
            >
              <img className="eucpn-logo" src={EuLogo} alt="eucpn logo" />
              <img
                src={applyAnimation ? EucpnLogoWhite : EucpnLogo}
                alt="eucpn logo"
              />
            </div>
          </div>
        ) : (
          <QuizPage />
        )}
        {isMobileDevice && applyAnimation ? (
          <div className="menu-container fade-in-container ">
            <MobileMenu onLearnMoreSelected={onLearnMoreSelected} />
          </div>
        ) : null}
        <div
          className={
            isMobileDevice
              ? "language-selection mobile-version fade-in-container"
              : "language-selection fade-in-container"
          }
        >
          {!showQuiz && applyAnimation && applyAnimation ? (
            <LanguageSelection />
          ) : null}
        </div>
        {!isMobileDevice && !showQuiz && applyAnimation ? (
          <div className="officer-wild-intro-page-container fade-in-container">
            <OfficerWild
              title={t("OfficerWildIntroTitle")}
              content={t("OfficerWildIntro")}
            />
          </div>
        ) : null}
        {(!showQuiz && !isMobileDevice && applyAnimation) ||
        (quizPage === pages.quizInfoPage && !isMobileDevice) ? (
          <div
            className={
              quizPage !== pages.quizInfoPage
                ? "quiz-button-container fade-in-container"
                : "take-quiz-button-container"
            }
          >
            <Fab
              color={quizPage === pages.quizInfoPage ? "secondary" : "primary"}
              aria-label="add"
              onClick={onTakeQuizClick}
            >
              <ExpandMoreIcon />
            </Fab>
            <div className="label">
              {quizPage === pages.quizInfoPage
                ? t("TakeTheQuiz")
                : t("TestYourKnowledge")}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
