import * as React from "react";
import * as LanguageActionTypes from "./language.actionTypes";

const initialState = {
  selectedLanguage: null,
  availableLanguages: [],
  loadingLanguages: false,
  error: null,
};

const LanguageContext = React.createContext<{
  state?: any;
  dispatch?: React.Dispatch<any>;
}>({});

const regionReducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case LanguageActionTypes.SelectLanguage:
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    case LanguageActionTypes.LoadLanguages:
      return {
        ...state,
        availableLanguages: [],
        loadingLanguages: true,
      };
    case LanguageActionTypes.LoadLanguagesSuccess:
      return {
        ...state,
        availableLanguages: action.payload,
        loadingLanguages: false,
      };
    case LanguageActionTypes.LoadLanguagesFail:
      return {
        ...state,
        loadingLanguages: false,
        error: action.payload,
      };

    case LanguageActionTypes.ClearLanguages:
      return initialState;

    default:
      return {
        ...state,
      };
  }
};

const LanguageProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(regionReducer, initialState);
  const value = { state, dispatch };
  return (
    <LanguageContext.Provider value={value}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
