export class SectionModel {
  sectionNumber!: string;
  title!: string;
  teaser!: string;
  content!: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
