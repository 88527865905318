import { forEachChild } from "typescript";
import {
  RegionModel,
  AnswerModel,
  QuestionModel,
  QuizModel,
  LanguageModel,
} from "../models";
import { InfoContentModel } from "../models/info-content.model";
import { QuizScoreModel } from "../models/quiz-score.model";
import { SectionModel } from "../models/section.model";

export class BackendParser {
  constructor() {}

  parseRegions(regions: any) {
    let regionsList: RegionModel[] = [];
    regions.map((region: any) => {
      const newRegion = new RegionModel({
        id: region.id,
        abbreviation: region.abbreviation,
        name: region.name,
        languages: region.languages,
        icon: region.icon,
        domain: region.domain,
        i18n: region.i18n,
      });
      regionsList.push(newRegion);
    });
    return regionsList;
  }

  parseAnswers(answers: any) {
    let answersList: AnswerModel[] = [];
    answers.map((answer: any) => {
      const newAnswer = new AnswerModel({
        id: answer.answerId,
        isActive: answer.isActive,
        isDesired: answer.isDesired,
        description: answer.description,
        answer: answer.answer,
        position: answer.position,
      });
      answersList.push(newAnswer);
    });
    return answersList;
  }

  parseQuestions(questions: any) {
    let questionsList: QuestionModel[] = [];
    const chapters = [1, 2, 3, 4];
    chapters.forEach((chapter) => {
      if (questions[chapter]) {
        questions[chapter].map((question: any) => {
          const newQuestion = new QuestionModel({
            id: question.questionId,
            position: question.position,
            question: question.question,
            description: question.description,
            type: question.type,
            answers: this.parseAnswers(question.answers),
            chapter: chapter,
          });
          questionsList.push(newQuestion);
        });
      }
    });
    return questionsList;
  }

  parseQuizzes(quizzes: any) {
    let quizList: QuizModel[] = [];
    quizzes.map((quiz: any) => {
      const newQuiz = new QuizModel({
        id: quiz.questionnaireId,
        title: quiz.title,
        questions: this.parseQuestions(quiz.questions),
      });
      quizList.push(newQuiz);
    });
    return quizList;
  }

  parseLanguages(languages: any): Array<LanguageModel> {
    let languageList: LanguageModel[] = [];
    languages.map((language: any) => {
      const newLanguage = new LanguageModel({
        id: language.id,
        name: language.name,
        icon: language.icon,
        code: language.code,
      });
      languageList.push(newLanguage);
    });
    return languageList;
  }

  parseQuizScore(score: any): QuizScoreModel {
    const quizScore = new QuizScoreModel({
      correctPct: score[0].correctpct,
      count: score[0].count,
      icon: score[0].icon,
      contributionPercentage: (Math.round(score[0].contributionpercentage * 100) / 100).toFixed(2),
      contribution: (Math.round(score[0].contribution * 100) / 100).toFixed(2)
    });
    return quizScore;
  }

  parseRegionsScore(scores: any): Array<QuizScoreModel> {
    let scoresList: QuizScoreModel[] = [];
    scores.map((score: any) => {
      const newScore = new QuizScoreModel({
        regionId: score.regionId,
        regionName: score.region,
        correctPct: score.correctpct,
        count: score.count,
        icon: score.icon,
        contributionPercentage: (Math.round(score.contributionpercentage * 100) / 100).toFixed(2),
        contribution: (Math.round(score.contribution * 100) / 100).toFixed(2)
      });
      scoresList.push(newScore);
    });
    return scoresList;
  }

  parseInfoContent(content: any): InfoContentModel[] {
    let contentList: InfoContentModel[] = [];
    let order = 1;
    content.map((item: any) => {
      const newContent = new InfoContentModel({
        id: item.id,
        title: item.title,
        subtitle: item.subtitle,
        content: item.content,
        regionId: item.regionId,
        region: item.region,
        icon: item.icon,
        language: item.language,
        sections: this.parseInfoSections(item.sections),
        abbr: item.abbr,
        order: order
      });
      order += 1;
      contentList.push(newContent);
    });
    return contentList;
  }

  parseInfoSections(sections: any): Array<SectionModel> {
    let sectionsList: SectionModel[] = [];
    sections.map((section: any) => {
      const newSection = new SectionModel({
        sectionNumber: section.title.substring(0, 3),
        title: section.title.substring(3, section.title.length),
        teaser: section.teaser,
        content: section.content,
      });
      sectionsList.push(newSection);
    });

    return sectionsList;
  }
}
