import { Button, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { QuizContext } from "../../reducers/quiz.reducer";
import "./QuizScore.component.scss";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import greenCheck from "../../assets/icons/green-check.svg";
import euFlag from "../../assets/icons/eu-flag.svg";
import { useMediaQuery } from "react-responsive";
import Fab from "@material-ui/core/Fab";
import earth from "../../assets/icons/earth-white.svg";
import contactUs from "../../assets/icons/contact-us.svg";
import asterix from "../../assets/icons/asterix.svg";
import { useHistory } from "react-router-dom";
import { ContentContext } from "../../reducers/content.reducer";
import * as ContentActionTypes from "../../reducers/content.actionTypes";
import { mobileDeviceResolution, pages } from "../../utils/utils";
import { RegionContext } from "../../reducers/region.reducer";
import { RegionModel } from "../../models/region.model";
import * as QuizActionTypes from "../../reducers/quiz.actionTypes";

interface QuizScoreProps {
  selectedRegion: RegionModel;
  questionsReport: string;
  regionReport: string;
  globalCount: number;
  globalReport: string;
  quizNote: string;
}

export function QuizScore(props: QuizScoreProps) {
  const history = useHistory();
  const { state, dispatch } = useContext(QuizContext);
  const { t, i18n } = useTranslation();
  const [timer, setTimer] = useState(0);
  const isMobileDevice = useMediaQuery({ query: mobileDeviceResolution });
  const contentContext = useContext(ContentContext);

  const detailledReportLabelButton = t("DetailledReport");
  useEffect(() => {
    if (state.startTime && state.endTime) {
      setTimer(state.endTime.getTime() - state.startTime.getTime());
    }
  }, [state]);

  function msToTime(duration: number, unit: string) {
    const milliseconds = Math.floor((duration % 1000) / 100);
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    let hoursToDisplay = hours < 10 ? "0" + hours : hours;
    let minutesToDisplay = minutes < 10 ? "0" + minutes : minutes;
    let secondsToDisplay = seconds < 10 ? "0" + seconds : seconds;

    switch (unit) {
      case "hours":
        return hoursToDisplay;
      case "minutes":
        return minutesToDisplay;
      case "seconds":
        return secondsToDisplay;
      default:
        return "";
    }
  }

  function onGlobalRankingClick() {
    let path = "ranking";
    history.push(path);
  }

  function onContactUsClick() {
    contentContext.dispatch?.({
      type: ContentActionTypes.SetOpenContentNumber,
      payload: 5,
    });
  }

  function onShowDetailedReport() {
    dispatch?.({
      type: QuizActionTypes.SetCurrentQuizPage,
      payload: pages.detailedReportPage,
    });
  }

  return (
    <div
      className={
        !isMobileDevice
          ? "quiz-score-root-container fade-in-container"
          : "quiz-score-root-container fade-in-container mobile-version"
      }
    >
      {isMobileDevice ? (
        <>
          <div className="root-mobile-container">
            <div className="mobile-timer-container">
              <div className="timer-shape-mobile">
                {timer ? (
                  <div className="timer">
                    <Typography variant="h2">
                      {msToTime(timer, "hours")}
                    </Typography>
                    <div className="unit">{t("Hours")}</div>
                    <Typography variant="h2">
                      :{msToTime(timer, "minutes")}
                    </Typography>
                    <div className="unit">{t("Minutes")}</div>
                    <Typography variant="h2">
                      :{msToTime(timer, "seconds")}
                    </Typography>
                    <div className="unit">{t("Seconds")}</div>
                  </div>
                ) : (
                  ""
                )}
                <img className="asterix" src={asterix} alt="asterix" />
              </div>
            </div>
            <div className="info-text-container">
              <Typography>
                {t("ScoreDescriptionStart", {currency: t("Currency")})}
                <span className="report-amount">
                  {t("Currency")}
                  {Math.round((timer / 60000) * 25000)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                </span>
                {t("ScoreDescriptionEnd")}
              </Typography>
            </div>
            <div
              className="detailled-report-button-container"
              onClick={onShowDetailedReport}
            >
              <Button
                className="detailled-report-button"
                variant="contained"
                color="secondary"
              >
                {detailledReportLabelButton}
                <ExpandMoreIcon />
              </Button>
            </div>
            <div className="mobile-report-bottom-container">
              <div className="report-container">
                <div className="columns-container">
                  <div className="report-column">
                    <div className="subtitle">{t("YourScore")}</div>
                    <div className="main-info">{props.quizNote}</div>
                    <div className="numbers-container">
                      <img
                        className="numbers-flag"
                        src={greenCheck}
                        alt="corect-answers"
                      ></img>
                      {props.questionsReport}
                    </div>
                  </div>
                  <div className="report-column">
                    <div className="subtitle">{t("ContributeTo")}</div>
                    <div className="main-info">{props.selectedRegion.name}</div>
                    <div className="numbers-container">
                      <img
                        className="numbers-flag"
                        src={props.selectedRegion.icon}
                        alt="region-flag"
                      ></img>
                      {props.regionReport}
                    </div>
                  </div>
                  <div className="report-column">
                    <div className="subtitle">{t("Global")}</div>
                    <div className="main-info">{props.globalCount}</div>
                    <div className="numbers-container">
                      <img
                        className="numbers-flag"
                        src={euFlag}
                        alt="eu-flag"
                      ></img>
                      {props.globalReport}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="timer-top-container">
            <div className="background-container">
              <div className="timer-container">
                {timer ? (
                  <div className="timer">
                    <Typography variant="h1">
                      {msToTime(timer, "hours")}
                    </Typography>
                    <div className="unit">{t("Hours")}</div>
                    <Typography variant="h1">
                      :{msToTime(timer, "minutes")}
                    </Typography>
                    <div className="unit">{t("Minutes")}</div>
                    <Typography variant="h1">
                      :{msToTime(timer, "seconds")}
                    </Typography>
                    <div className="unit">{t("Seconds")}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="shape-container">
                <div className="info-text-container">
                  <Typography>
                  {t("ScoreDescriptionStart", {currency: t("Currency")})}
                    <span className="report-amount">
                      {t("Currency")}
                      {Math.round((timer / 60000) * 25000)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                    </span>
                    {t("ScoreDescriptionEnd")}
                  </Typography>
                </div>
                <img className="asterix" src={asterix} alt="asterix" />
              </div>
            </div>
          </div>
          <div className="report-bottom-container">
            <div className="report-container">
              <div className="columns-container">
                <div className="report-column">
                  <div className="subtitle">{t("YourScore")}</div>
                  <div className="main-info">{props.quizNote}</div>
                  <div className="numbers-container">
                    <img
                      className="numbers-flag"
                      src={greenCheck}
                      alt="corect-answers"
                    ></img>
                    {props.questionsReport}
                  </div>
                </div>
                <div className="report-column">
                  <div className="subtitle">{t("ContributeTo")}</div>
                  <div className="main-info">{props.selectedRegion.name}</div>
                  <div className="numbers-container">
                    <img
                      className="numbers-flag"
                      src={props.selectedRegion.icon}
                      alt="region-flag"
                    ></img>
                    {props.regionReport}
                  </div>
                </div>
                <div className="report-column">
                  <div className="subtitle">{t("Global")}</div>
                  <div className="main-info">{props.globalCount}</div>
                  <div className="numbers-container">
                    <img
                      className="numbers-flag"
                      src={euFlag}
                      alt="eu-flag"
                    ></img>
                    {props.globalReport}
                  </div>
                </div>
              </div>
              <div
                className="detailled-report-button-container"
                onClick={onShowDetailedReport}
              >
                <Button
                  className="detailled-report-button"
                  variant="contained"
                  color="secondary"
                >
                  {detailledReportLabelButton}
                  <ExpandMoreIcon />
                </Button>
              </div>
            </div>
          </div>
          <div className="buttons-container">
            <div className="contact-us-button-container">
              <Fab color="inherit" aria-label="add" onClick={onContactUsClick}>
                <img className="contact-us" src={contactUs} alt="contactUs" />
              </Fab>
              <div className="label">{t("ContactUs")}</div>
            </div>
            <div className="global-ranking-button-container">
              <Fab
                color="inherit"
                aria-label="add"
                onClick={onGlobalRankingClick}
              >
                <img className="earth" src={earth} alt="earth" />
              </Fab>
              <div className="label">{t("GlobalRanking")}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
