import apiService from "../services/api.service";
import * as QuizActionTypes from "../reducers/quiz.actionTypes";
import { QuizScoreModel } from "../models/quiz-score.model";

export const useGetRegionsScore = (dispatch: any) => {
  dispatch({ type: QuizActionTypes.LoadRegionsScore });
  apiService
    .getRegionsScore()
    .then((scores: Array<QuizScoreModel>) => {
      dispatch({
        type: QuizActionTypes.LoadRegionsScoreSuccess,
        payload: scores,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: QuizActionTypes.LoadRegionsScoreFail,
        payload: err.message,
      });
    });
};
