import * as React from "react";
import * as QuizActionTypes from "./quiz.actionTypes";

const initialState = {
  quizzes: [],
  loadingQuizzes: false,
  error: null,
  currentQuizPage: null,
  isSelectedAnswer: false,
  isEndOfChapter: false,
  startTime: null,
  endTime: null,
  correctAnswers: 0,
  initialScore: null,
  finalScore: null,
  globalScore: null,
  currentChapterNumber: 0,
  regionsScore: [],
};

const QuizContext = React.createContext<{
  state?: any;
  dispatch?: React.Dispatch<any>;
}>({});

const quizReducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case QuizActionTypes.SetCorrectAnswers: {
      return {
        ...state,
        correctAnswers: state.correctAnswers + 1,
      };
    }
    case QuizActionTypes.ClearCorrectAnswers: {
      return {
        ...state,
        correctAnswers: 0
      };
    }
    case QuizActionTypes.SetIsSelectedAnswer: {
      return {
        ...state,
        isSelectedAnswer: action.payload,
      };
    }
    case QuizActionTypes.SetIsEndOfChapter: {
      return {
        ...state,
        isEndOfChapter: action.payload,
      };
    }
    case QuizActionTypes.SetCurrentQuizPage: {
      return {
        ...state,
        currentQuizPage: action.payload,
      };
    }
    case QuizActionTypes.LoadQuizzes:
      return {
        ...state,
        quizzes: [],
        loadingQuizzes: true,
      };
    case QuizActionTypes.LoadQuizzesSuccess:
      return {
        ...state,
        quizzes: action.payload,
        loadingQuizzes: false,
      };
    case QuizActionTypes.LoadQuizzesFail:
      return {
        ...state,
        loadingQuizzes: false,
        error: action.payload,
      };

    case QuizActionTypes.SetStartTime:
      return {
        ...state,
        startTime: action.payload,
      };

    case QuizActionTypes.SetEndTime:
      return {
        ...state,
        endTime: action.payload,
      };

    case QuizActionTypes.LoadInitialQuizScore:
      return {
        ...state,
        initialScore: null,
      };
    case QuizActionTypes.LoadFinalQuizScore:
      return {
        ...state,
        finalScore: null,
      };
    case QuizActionTypes.LoadGlobalQuizScore:
      return {
        ...state,
        globalScore: null,
      };
    case QuizActionTypes.LoadRegionsScore:
      return {
        ...state,
        regionsScore: [],
      };
    case QuizActionTypes.LoadInitialQuizScoreSuccess:
      return {
        ...state,
        initialScore: action.payload,
      };
    case QuizActionTypes.LoadFinalQuizScoreSuccess:
      return {
        ...state,
        finalScore: action.payload,
      };
    case QuizActionTypes.LoadGlobalQuizScoreSuccess:
      return {
        ...state,
        globalScore: action.payload,
      };

    case QuizActionTypes.LoadRegionsScoreSuccess:
      return {
        ...state,
        regionsScore: action.payload,
      };

    case QuizActionTypes.SetCurrentChapterNumber:
      return {
        ...state,
        currentChapterNumber: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

const QuizProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(quizReducer, initialState);
  const value = { state, dispatch };
  return (
    <QuizContext.Provider value={value}>{props.children}</QuizContext.Provider>
  );
};

export { QuizContext, QuizProvider };
