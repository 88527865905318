import { Config, ConfigEnv } from "./models/config.model";

const buildConfig = (environment: ConfigEnv): Config => {
  switch (environment) {
    case ConfigEnv.DEV:
      return new Config({
        apiUrl: 'https://eucpn.noticed.be',
      });
    case ConfigEnv.PRODUCTION:
      return new Config({
        apiUrl: 'https://eucpnlive.noticed.be',
      });
    default:
      return buildConfig(ConfigEnv.PRODUCTION);
  }
};

const env =
  process.env.REACT_APP_STAGE === ConfigEnv.PRODUCTION
    ? ConfigEnv.PRODUCTION
    : ConfigEnv.DEV;
export const config = buildConfig(env);
