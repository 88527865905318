import apiService from "../services/api.service";
import * as ContentActionTypes from "../reducers/content.actionTypes";

export const getContent = (dispatch: any, regionId: number, langId: number) => {
  dispatch({
    type: ContentActionTypes.LoadContent,
    payload: { regionId: regionId, languageId: langId },
  });
  apiService
    .getInfoContent(regionId, langId)
    .then((content: any) => {
      dispatch({
        type: ContentActionTypes.LoadContentSuccess,
        payload: content,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: ContentActionTypes.LoadContentFail,
        payload: err.message,
      });
    });
};
